import { Component, OnInit, Input, ViewChild, HostListener, ElementRef, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import {Chat} from '../../../models/chat';
import {Message, MessageMention} from '../../../models/message';
import {Attachment} from '../../../models/attachment';
import {MessageLocationBody} from '../../../models/message-location-body';

import {ChatRoomService} from './chat-room.service';
import {ChatService} from '../../services/data/chat/chat.service';
import {ChatMessageService} from '../../services/data/messages/chat-message/chat-message.service';

import {AttachmentService} from '../../../utilities/attachment/attachment.service';
import {FileUploaderService} from '../../../utilities/file-uploader/file-uploader.service';
import {LocationSelectorService} from '../../../utilities/location-selector/location-selector.service';

import {ChatGroupSettingComponent} from '../chat-group-setting/chat-group-setting.component';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {TnDialogService} from '../../../utilities/tn-dialog/tn-dialog.service';
import {UserContact} from '../../../models/user-contact';
import {ContactCardService} from '../../contact/contact-card/contact-card.service';
import {InputValidationService} from '../../../utilities/input-validation/input-validation.service';

import * as _ from 'lodash';
import {TnNotificationService} from '../../../utilities/tn-notification/tn-notification.service';
import {FileUploader} from 'ng2-file-upload';
import {PasswordReloginService} from '../../../utilities/password-relogin/password-relogin.service';
import {LoggerService} from '../../../utilities/logger/logger.service';
import {WatermarkService} from '../../../utilities/watermark/watermark.service';
import {ContextMenuService} from '../../../utilities/context-menu/context-menu.service';
import {TeamnoteConfigService} from '../../../configs/teamnote-config.service';
import {ContactPickerService} from '../../contact-picker/contact-picker.service';
import {LocalStorageManagerService} from '../../../utilities/local-storage/local-storage-manager.service';
import {CONTACT_PICKER_ACTION} from '../../contact-picker/contact-picker.component';
import {TeamNoteLocalStorageKeyConstants} from '../../../constants/local-storage-key.constant';
import {ChatConstant} from '../../../constants/chat.constant';
import {PresenceTypeConstant} from '../../../constants/presence-type.constant';
import {MessageTypeConstant} from '../../../constants/message-type.constant';
import {AttachmentTypeConstant} from '../../../constants/attachment-type.constant';
import {AMQPRoutingKey} from '../../../constants/amqp-routing-key.constant';
import {UserContactService} from '../../services/data/user-contact/user-contact.service';
import {UserConstant} from '../../../constants/user.constant';
import {AccountManagerService} from '../../services/account/account-manager.service';
import {TimestampService} from '../../../utilities/timestamp/timestamp.service';
import {UtilitiesService} from '../../../utilities/service/utilities.service';
import {ExportMessageComponent} from './export-message/export-message.component';
import {ModuleManagerService} from '../../services/module/module-manager.service';
import {ModuleKeyDefinition} from '../../../constants/module.constant';
import {AttachmentImageGridComponent} from '../../../utilities/attachment/attachment-image-grid/attachment-image-grid.component';
import {AttachmentVideoGridComponent} from '../../../utilities/attachment/attachment-video-grid/attachment-video-grid.component';
import {AttachmentPdfListComponent} from '../../../utilities/attachment/attachment-pdf-list/attachment-pdf-list.component';
import {PasteImageHelperService} from '../../../utilities/paste-image-helper/paste-image-helper.service';
import {ImageEditorService} from '../../../utilities/image-editor/image-editor.service';
import {FileUploadTarget} from '../../../utilities/file-uploader/file-upload-target';
import {FileManagerService} from '../../../utilities/file-manager/file-manager.service';
import {AudioPlayerService} from '../../../utilities/audio-player/audio-player.service';
import {TeamNoteCorporateMaterialConstant} from '../../corporate-material/constants/corporate-material.constant';
import {CorporateMaterialPickerService} from '../../shared/corporate-material-picker/corporate-material-picker.service';
import {CorporateMaterialFile} from '../../corporate-material/models/corporate-material';
import {Sticker} from '../../../models/sticker';
import {WebclientService} from '../../webclient.service';
import {TranslateService} from '@ngx-translate/core';
import {SocketService} from '../../services/socket/socket.service';
import {StarredMessagesComponent} from '../../starred-messages/starred-messages.component';
import {InfoMessageService} from '../../services/data/messages/info-message/info-message.service';
import { trigger, style, animate, keyframes, group, query, transition, animation, useAnimation } from '@angular/animations'
import { MultiChatRoomService } from '../multi-chat-room.service';

export const CHAT_ROOM_MODE = {
  NORMAL: 0,
  FORWARD: 1,
  ANNOTATE: 2
};

const FadeAnimation = trigger('fadeAnimationTrigger', [
  transition(':enter', [
    style({ opacity: 0 }), // { width: 0, opacity: 0 }
    animate(
      '.4s linear',
      style({
        // width: '100%',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({ opacity: 1 }), // { width: '100%', opacity: 1 }
    animate(
      '.15s linear',
      style({
        // width: 0,
        opacity: 0
      })
    )
  ])
])

@Component({
  selector: 'tn-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss'],
  animations: [FadeAnimation]
})
export class ChatRoomComponent implements OnInit {
  @Input() chat: Chat;
  @Input() targetMessage: Message;
  @Output() exitSearchModeAndGoToChat: EventEmitter<{ chat: Chat, isQuickTravel: boolean, isNeedClearPrevMessageFirst?: boolean }> = new EventEmitter<{ chat: Chat, isQuickTravel: boolean, isNeedClearPrevMessageFirst?: boolean }>(null);
  @Output() enterSearchModeAndGoToChat: EventEmitter<{ msg: Message, prevMsg: Message }> = new EventEmitter<{ msg: Message, prevMsg: Message }>(null);
  @Output() enterChatSearchMode: EventEmitter<{ chat: Chat, keyword: string }> = new EventEmitter<{ chat: Chat, keyword: string }>(null);
  @Output() clearTargetMessageProps: EventEmitter<number> = new EventEmitter<number>(null);

  // For mutli chat room mode
  @Input() isInMultiChatRoomMode: boolean;
  @Input() multiChatFilterOptions: any;
  @Input() prevFilterSettings: any;
  @Input() currFilterSettings: any;
  @Input() enableAutoScroll: any;
  @Output() onFilterChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectChatRoom: EventEmitter<Chat> = new EventEmitter<Chat>();
  @Output() enterSearchModeWithChatIdAndGoToChat: EventEmitter<{ msg: Message, prevMsg: Message, chatId: string }> = new EventEmitter<{ msg: Message, prevMsg: Message, chatId: string }>(null);
  // @Output() updateChatLoadingHistoryState: EventEmitter<{chatId: string, loadingState: boolean}> = new EventEmitter<{chatId: string, loadingState: boolean}>(null);
  @Output() updateChatDraggableState: EventEmitter<{chatId: string, disabledDrag: boolean}> = new EventEmitter<{chatId: string, disabledDrag: boolean}>(null);
  
  isGroupChat: boolean = false;

  messages: Message[] = [];

  isSearchedPrevMessage: boolean = false;
  isSearchedNextMessage: boolean = false;

  // Messages pointer
  firstMessagePointer: Message = null;
  lastMessagePointer: Message = null;

  // For loading history controls
  isAtChatEarliest: boolean = false;
  isAtChatLatest: boolean = false;

  inputMessage: string = '';
  inputTextAreaMaxHeight: number = 60;
  isAutoAdjustHeight: boolean = false;
  isLoadedInitHistory: boolean = false;
  isLoadingHistory: boolean = false;
  scrollToBottom: boolean = true;
  currentHeight: number = 0;

  loadHistoryTimeout: number = 1000;

  isSentInitialRead: boolean = false;

  // whisper
  whisperingTarget: UserContact = null;
  // reply
  replyingMessage: Message = null;
  private replyMsgSub: Subscription;
  private searchModeSub: Subscription;

  // reply
  annotatingMessage: Message = null;

  // drag file
  draggingFileUploader: FileUploader = new FileUploader({ url: null });
  isDraggingFileOver: boolean = false;

  // relogin overlay
  RELOGIN_ACTIONS = {
    NONE: 0,
    ENCRYPTED: 1,
    CONFIDENTIAL: 2
  };
  reloginOverlayTitle: string = null;
  reloginAction: number = this.RELOGIN_ACTIONS.NONE;

  // secure message
  isAllowEncryptedMessage: boolean;
  isToggledEncryptedMessage: boolean = false;
  isUnlockedEncryptedMessage: boolean = false;
  requestedUnlockMessage: Message = null;

  // classification
  idleTimeout: any = null;
  targetIdleSecond: number;

  // Selection
  CHAT_ROOM_MODE = CHAT_ROOM_MODE;
  chatRoomMode: number = CHAT_ROOM_MODE.NORMAL;
  selectedMessageIds: string[] = [];

  @ViewChild('messagesElement', {static: false}) messagesElement: ElementRef;

  @ViewChild('messageInputTextarea', {static: false}) messageInputTextarea: ElementRef;
  inputTextareaPlaceholerTranslateKey: string;

  @ViewChild('bottomRightMenu', {static: false}) bottomRightMenu: ElementRef;
  bottomRightMenuDisplayTriggerHeight: number = 100;
  shouldBottomRightMenuDisplay: boolean = false;

  @ViewChild('securityOverlayCanvas', {static: true}) securityOverlayCanvas: ElementRef;

  // Out of office
  numOfOutOfOfficeMember: number = 0;
  outOfOfficeExpiredTimestamp: number = 0;

  // Export Chat
  isEnableExportChat: boolean = false;

  // Floating Date
  floatingDateValue: string = null;
  isShowFloatingDate: boolean = false;
  scrollingFloatDateInterval: any;

  // Mention
  isEnableMention: boolean = false;
  isInMentionMode: boolean = false;
  mentionSelectionList: UserContact[] = [];
  mentionedMembers: MessageMention[] = [];

  // Hashtag
  isEnableHashtag: boolean = false;
  isInHashtagMode: boolean = false;
  hashtagSelectionList: string[] = [];
  chatTags: string[] = [];

  // Search
  isEnableSearch: boolean = false;

  // Options
  isEnableAttach: boolean = false;
  isEnableImportant: boolean = false;
  isImportant: boolean = false;
  isEnableSms: boolean = false;
  isSms: boolean = false;

  // Acknowledge
  isAckToRead: boolean = false;

  // Message
  isEnableMsgAck: boolean = false;

  // Star message
  isEnableStarMessage: boolean = false;
  loadHistorySize: number = null
  targetMessageWasFlashed: boolean = false;

  // Message Annotation
  isEnableMessageAnnotation: boolean = false;

  // select from document sharing
  isEnabledSelectFromDoc: boolean = false;
  isEnableAttachCorporateMaterial: boolean = false;

  // sticker
  isEnableSticker: boolean = false;
  isOpenStickerSelection: boolean = false;

  // userField
  showUserField: boolean = false;
  userField: any;

  // important users
  isEnableImportantUsers: boolean = false;

  // message delete
  isEnableMessageDelete: boolean = false;

  // allow attachment download
  isAllowAttachmentSave: boolean = false;

  // disallow security msg download
  isDisableEncrypyedMsgDownload: boolean = false

  isEnableMarkdownMessageInput: boolean = false;
  isShowSendBar: Boolean = false;

  // prevMembersFilterSettings: any = null;
  private multiChatRoomMessageSubjectSub: Subscription;
  isNeedStopAutoLoading: boolean = true
  autoLoadMessageTimer: any = null;

  componentEnded: boolean = false;

  // isEnableChatroomAutoScroll: { isMsgSendAction?: boolean; isNewMsgAction?: boolean } | null = { isMsgSendAction: false, isNewMsgAction: false };
  isEnableChatroomAutoScroll: { isMsgSendAction?: boolean; isNewMsgAction?: boolean } | null = null;

  private activeChatRoomMessageSub: Subscription;

  realMessageTypes = [
    MessageTypeConstant.TEXT,
    MessageTypeConstant.ATTACHMENT,
    MessageTypeConstant.LOCATION,
    MessageTypeConstant.STICKER
  ];

  constructor(
    private _translate: TranslateService,
    private _chatMessageService: ChatMessageService,
    private _webclientService: WebclientService,
    private _chatRoomService: ChatRoomService,
    private _fileManagerService: FileManagerService,
    private _attachmentService: AttachmentService,
    private _fileUploaderService: FileUploaderService,
    private _locationSelectorService: LocationSelectorService,
    private _dialog: MatDialog,
    private _tnDialogService: TnDialogService,
    private _contactCardService: ContactCardService,
    private _inputValidationService: InputValidationService,
    private _tnNotificationService: TnNotificationService,
    private _passwordReloginService: PasswordReloginService,
    private _chatService: ChatService,
    private _loggerService: LoggerService,
    private _watermarkService: WatermarkService,
    private _contextMenuService: ContextMenuService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _contactPickerService: ContactPickerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _userContactService: UserContactService,
    private _accountManagerService: AccountManagerService,
    private _timestampService: TimestampService,
    private _utilitiesService: UtilitiesService,
    private _moduleManagerService: ModuleManagerService,
    private _pasteImageHelperService: PasteImageHelperService,
    private _imageEditorService: ImageEditorService,
    private _audioPlayerService: AudioPlayerService,
    private _corporateMaterialPickerService: CorporateMaterialPickerService,
    private _socketService: SocketService,
    private _infoMessageService: InfoMessageService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _multiChatRoomService: MultiChatRoomService,
  ) {
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.isAllowEncryptedMessage = config.WEBCLIENT.CHATROOM.ENABLE_ENCRYPTED_MESSAGE;
      this.targetIdleSecond = config.WEBCLIENT.CHATROOM.CONFIDENTIAL_CHAT_IDLE_TIME_SECOND;
      this.isEnableMention = config.WEBCLIENT.CHATROOM.IS_ENABLE_MENTION;
      this.isEnableHashtag = config.WEBCLIENT.CHATROOM.IS_ENABLE_HASHTAG;
      this.isEnableSearch = config.WEBCLIENT.CHATROOM.IS_ENABLE_SEARCH;
      this.isEnableAttach = config.WEBCLIENT.CHATROOM.IS_ENABLE_ATTACH;
      this.isEnableAttachCorporateMaterial = config.WEBCLIENT.CHATROOM.IS_ENABLE_ATTACH_CORPORATE_MATERIAL;
      this.isEnableImportant = config.WEBCLIENT.CHATROOM.IS_ENABLE_IMPORTANT;
      this.isEnableSms = config.WEBCLIENT.CHATROOM.IS_ENABLE_SMS;
      this.inputTextAreaMaxHeight = config.WEBCLIENT.CHATROOM.INPUT_TEXTAREA_MAX_HEIGHT;
      this.isAckToRead = config.WEBCLIENT.CHATROOM.IS_ACK_TO_READ;
      this.isEnableMsgAck = config.WEBCLIENT.CHATROOM.IS_ENABLE_MESSAGE_ACK;
      this.isAutoAdjustHeight = config.WEBCLIENT.CHATROOM.IS_AUTO_ADJUST_HEIGHT;
      this.isDisableEncrypyedMsgDownload = config.WEBCLIENT.CHATROOM.IS_DISABLE_ENCRYPTED_MSG_DOWNLOAD;
      this.isEnableMarkdownMessageInput = config.WEBCLIENT.CHATROOM.IS_ENABLE_MARKDOWN_INPUT;
    });

    this.replyMsgSub = this._chatRoomService.replyingMessage$.subscribe(rm => {
      if (rm?.msg) {
        if (!this.isInMultiChatRoomMode) {
          this.toggleReply({message: rm.msg})
        } else {
          this.toggleReply({message: rm.msg, chatId: rm.chatId})
        }
      }
    });
  }

  ngOnInit() {
    this._loggerService.debug('Chat room ngOnInit');
    this.isEnableExportChat = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EXPORT);
    this.isEnabledSelectFromDoc = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.CORPORATE_MATERIAL);
    this.isEnableSticker = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.STICKER);
    this.isEnableStarMessage = this._webclientService.checkIfEnableMessageStar();
    this.isEnableMessageDelete = this._webclientService.checkIfEnableMessageDelete();
    this.isEnableMessageAnnotation = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_ANNOTATION);
    this.isEnableImportantUsers = this._webclientService.checkIfEnableImportantUsers();
    this.isAllowAttachmentSave = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.ATTACHMENT_SAVE);
    this.setInputTextareaPlaceholder();
    this.getUserField();

    this.isInMultiChatRoomMode && this.subscribeScrollTriggerSubject();
  }

  /**
   * Fired when "chat" is changed
   *
   * 1. Perform neccessary inits
   *
   * @memberof ChatRoomComponent
   */
  ngOnChanges() {
    // console.log('this.multiChatFilterOptions', this.multiChatFilterOptions);
    // console.log('this.prevFilterSettings', this.prevFilterSettings);
    // console.log('this.currFilterSettings', this.currFilterSettings);
    if (this.isInMultiChatRoomMode) {
      if (this.checkIfNeedSetUpChatRoomInFilteringMode()) {
        return;
      }
    }
    // console.log('target message', this.targetMessage)
    this.setUpChatRoom();
  }

  // ngAfterViewInit() {
  ngAfterContentInit() { // fix ExpressionChangedAfterItHasBeenCheckedError
    // console.log('ngAfterContentInit targetMessage', this.targetMessage);
    if (this.targetMessage?.isFilterPointer) {
      // console.log('need setUpChatRoom in ngAfterContentInit');
      this.setUpChatRoom();
    } else {
      this.focusOnInputTextarea();
    }
  }

  /**
   * Remove active chat room subscription
   *
   * @memberof ChatRoomComponent
   */
  ngOnDestroy() {
    this.clearAutoMsgHistoryApiLoaderStuff();
    console.log('ngOnDestroy !!!');

    this.removeConfidentialChatMessages();
    this.stopAllChatroomAudios();

    if (this.activeChatRoomMessageSub) {
      this.activeChatRoomMessageSub.unsubscribe();
    }

    this.removeActiveChatRoomSubscription();
    this.replyMsgSub.unsubscribe();
    
    this.unsubscribeTriggerScrollSubject()
    this.removeTriggerScrollSubscription();

    this.removeMuitiChatRoomSubscription();

    // if (this.targetMessage) {
    //   this.exitSearchedMessageDisplayMode();
    // }

    this.unsubscribeSearchingModeBehaviorSubject();
    this.unsubscribeMultiChatRoomMessageSubject();
    this.removeChatSearchedMessageByChatId();

    this.targetMessage = null;
    // this.targetMessageWasFlashed = false
    // remove tracking user ids
    this._userContactService.setTrackingUserContactIds([]);

    this.componentEnded = true;
  }

  setUpChatRoom(): void {
    // Stop all playing audios
    this.stopAllChatroomAudios();

    // Reset flag when chat room is changed
    this.isSentInitialRead = false;

    // Update route meta data
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, this.chat.chat_id);

    // Init chat room mode and selected messages
    this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
    this.selectedMessageIds = [];

    // Init message pointers
    this.firstMessagePointer = null;
    this.lastMessagePointer = null;

    // Init chat edging status
    this.isAtChatEarliest = false;
    this.isAtChatLatest = false;

    this.isOpenStickerSelection = false;

    if (!this.isInMultiChatRoomMode) {
      this.targetMessageWasFlashed = false;
    }

    this.isNeedStopAutoLoading = true;
    // this.clearAutoMsgHistoryApiLoaderStuff();

    this.checkFilterIfNeedReset();

    console.log('setUpChatRoom');
    this.tryToUnsubscribePrevMessageSubject();

    // Check if chat is CONFIDENTIAL
    if (this.chat.security_level == ChatConstant.SECURITY_LEVEL.RESTRICTED) {
      this.lockConfidentialChat();
    } else {
      // If not, init chat room
      this.initializeChatRoom();
    }
  }

  stopAllChatroomAudios(): void {
    // Pause all playing audios
    this._audioPlayerService.stopAllAudioMessage();
  }

  /**
   * Delete active chat room message subject
   *
   * @memberof ChatRoomComponent
   */
  removeActiveChatRoomSubscription(): void {
    this._chatMessageService.deleteActiveChatRoomMessageSubject();
  }

  /**
   * Delete multi chat room message subject by chat_id
   *
   * @memberof ChatRoomComponent
   */
  removeMuitiChatRoomSubscription(): void {
    this._chatMessageService.removeMultiChatRoomBehaviorSubjectByChatId(this.chat.chat_id);
  }

  removeChatSearchedMessageByChatId(): void {
    this._chatMessageService.removeChatSearchedMessageByChatId(this.chat.chat_id);
  }

  unsubscribeSearchingModeBehaviorSubject(): void {
    if (this.searchModeSub) {
      this._chatMessageService.removeActiveChatRoomSearchedBehaviorSubjectByChatId(this.chat.chat_id);

      this._loggerService.log('searchModeSub.unsubscribe() before exitSearchedMessageDisplayMode')
      this.searchModeSub.unsubscribe();
    }
  }
  
  unsubscribeMultiChatRoomMessageSubject(): void {
    if (this.multiChatRoomMessageSubjectSub) {
      this.multiChatRoomMessageSubjectSub.unsubscribe();
    }
  }

  unsubscribeTriggerScrollSubject(): void {
    let subscriber = this._chatRoomService.getTriggerScrollSubjectByChatId(this.chat.chat_id);
    if (subscriber) {
      subscriber.unsubscribe();
    }
  }

  removeTriggerScrollSubscription(): void {
    this._chatRoomService.removeBehaviorSubjectByChatId(this.chat.chat_id);
  }

  /**
   * Chat room back button
   *
   * Update active chat subject to be {null}
   *
   * @memberof ChatRoomComponent
   */
  chatRoomBack(): void {
    this._loggerService.debug('Chat room back');
    this._loggerService.debug('Leaving chatroom: ' + this.chat.chat_id + this.chat.name);
    this._chatService.updateActiveChatSubject(null);

    // Update route meta data
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, '');
  }

  focusOnInputTextarea(): void {
    if (!this.isEnableMarkdownMessageInput) {
      if (this.messageInputTextarea) {
        this.messageInputTextarea.nativeElement.focus();
      }
    } else {
      if (this.editableDiv) {
        this.editableDiv.nativeElement.focus();
      }
    }
  }

  setInputTextareaPlaceholder(): void {
    if (this._teamnoteConfigService.isBrowserIE) {
      this.inputTextareaPlaceholerTranslateKey = 'WEBCLIENT.CHATROOM.TEXTAREA_PLACEHOLDER.IE';
    } else {
      this.inputTextareaPlaceholerTranslateKey = 'WEBCLIENT.CHATROOM.TEXTAREA_PLACEHOLDER.OTHERS';
    }
    if (this.whisperingTarget) {
      this.inputTextareaPlaceholerTranslateKey = 'WEBCLIENT.CHATROOM.TEXTAREA_PLACEHOLDER.TEXT_ONLY';
    }
  }

  /**
   * Initialize Chat Room
   *
   * - Reset all flag, inputs
   * - Subscribe to chat via AMQP
   *
   * @memberof ChatRoomComponent
   */
  initializeChatRoom(): void {
    if (!this.targetMessage) {
      this.realChatRoomEnteringInit();
    } else {
      this.searchModeChatRoomInit();
    }

    // Display flags
    this.currentHeight = 0;

    if (!this.isInMultiChatRoomMode) {
      this.scrollToBottom = true;
    } else {
      console.log('scrollToBottom after reseting filter');
      // const prevMembersFilterSettings = this.prevFilterSettings ? _.map(this.prevFilterSettings.selectedChatMembers, 'user_id') : null;
      // const currentMembersFilterSettings = _.map(this.currFilterSettings.selectedChatMembers, 'user_id');
      if (!this.scrollToBottom) {
        if (!_.isEqual(this.prevMembersFilterSettings, this.currentMembersFilterSettings)) {
          if (this.currentMembersFilterSettings.length === 0) {
            console.log('scrollToBottom after reset Member Filter');
            this.scrollToBottom = true;
          }
        }
  
        if (!_.isEqual(this.prevDateTimeFilterSettings, this.currentDateTimeFilterSettings)) {
          if (_.isEmpty(this.currentDateTimeFilterSettings?.selectedDateRange)) {
            console.log('scrollToBottom after reset Date-Time Filter');
            this.scrollToBottom = true;
          }
        }
      }

      // this.prevMembersFilterSettings = currentMembersFilterSettings;
    }
      
    
    this.loadHistorySize = null

    // Reset inputs
    this.resetAllInputs();

    // Encrypted Message
    this.isUnlockedEncryptedMessage = false;

    // Confidential chat
    this.drawSecurityOverlay();

    // Reset relogin action
    this.reloginAction = this.RELOGIN_ACTIONS.NONE;

    // Set isGroupChat Flag
    this.isGroupChat = this.chat.t == PresenceTypeConstant.GROUP_CHAT || this.chat.t == PresenceTypeConstant.GROUP_BROADCAST;

    // classification
    this.resetIdleTimeout(true);

    // subscribe chat
    this._chatRoomService.subscribeChat(this.chat.chat_id);

    // Out of office
    this.trackChatRoomUserState();

    // option
    this.isImportant = false;
    this.isSms = false;

    this.focusOnInputTextarea();

    // multi-chat behaviour
    if (this.isMemberFiltering || this.isDateTimeFiltering) {
      this.closeSendBarAndReplyBar();
    }
  }

  /**
   * Real Init of chat room
   *
   * 1. Init active chat room message subject
   * 2. Get current local chat messages
   * 3. If # of msgs is less than LOAD_HISTORY_SIZE, load history once. If not, update message pointers for future message handling
   * 4. Subscribe to activeChatRommMessage subject
   *
   * @memberof ChatRoomComponent
   */
  realChatRoomEnteringInit(): void {
    if (this.isInMultiChatRoomMode) {
      // this.unsubscribeMultiChatRoomMessageSubject();
      console.log('------------------------isInMultiChatRoomMode------------------------');
      this._chatMessageService.initMessageSubject(this.chat.chat_id);

      this.multiChatRoomMessageSubjectSub = this._chatMessageService.getMessageSubjectByChatId(this.chat.chat_id).subscribe(newMessages => {
        console.log('multiChatRoomMessageSubjects handleMessageSubejctUpdate', newMessages);

        // check auto scroll under no filtering mode
        if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
          if (this.checkNewMessageIsRealMessage(newMessages)) {
            if (this.enableAutoScroll?.enabled) {
              if (this.checkNewMessageIsSentByOthers(newMessages)) {
                this.scrollToBottom = true;
                this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
              } else {
                // this.scrollToBottom = true;
                this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
              }
            } else {
              if (this.checkNewMessageIsSentByOthers(newMessages)) {
                this.checkIfBottomRightMenuShouldDisplay();
                this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
              } else {
                this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: true, isNewMsgAction: false })
              }
    
              // this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
              // this.updateIsEnableChatroomAutoScroll(null)
            }
          }
        }
        
        
        this.handleMessageSubejctUpdate();
      });
    } else {
      // Init active chat room message subject
      this._chatMessageService.initActiveChatRoomMessageSubject(this.chat.chat_id);

      if (this.activeChatRoomMessageSub) {
        this.activeChatRoomMessageSub.unsubscribe();
      }
      
      this.activeChatRoomMessageSub = this._chatMessageService.activeChatRoomMessages$.subscribe(messages => {
        // this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
        this.handleMessageSubejctUpdate();
      });
    }

    let loadMessageSize = this.loadHistorySize ? this.loadHistorySize : this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE
    // console.log('this.messages.length need load this.loadHistory();???', this.messages.length, this.messages.length < loadMessageSize);
    if (this.messages.length < loadMessageSize) {
      this.loadHistory();
    } else {
      this.updateFirstMessagePointer();
      this.updateLastMessagePointerByUnreadCount();
    }
  }

  checkNewMessageIsRealMessage(messages): boolean {
    if (messages.length === 0) {
      return false;
    }

    return _.includes(this.realMessageTypes, _.toInteger(messages[0]?.type)) 
  }

  checkNewMessageIsSentByOthers(messages): boolean {
    // if (messages.length === 0) {
    //   return false;
    // }
    
    return messages[0]?.sent_by !== this._accountManagerService.userId;
  }

  tryToClearTargetMessage(): void {
    if (this.isMemberFiltering || this.isDateTimeFiltering || this.targetMessageWasFlashed) {
      this.scrollToBottom = true;
      return;
    }
    
    if (this.isSearchedPrevMessage && this.isSearchedNextMessage) {
      // console.warn('scroll to target', this.targetMessage.body);
      setTimeout(() => {
        this.scrollToTargetMessage(this.targetMessage);
      }, 1000);
    }
  }

  clearTargetMessage(): void {
    this.clearTargetMessageProps.emit();
  }

  /**
   * Prepare messages for searched message display
   *
   * @memberof ChatRoomComponent
   */
  searchModeChatRoomInit(): void {
    // this.unsubscribeSearchingModeBehaviorSubject();

    this._chatMessageService.initActiveChatRoomMessageSubject(this.chat.chat_id, true);

    this.searchModeSub = this._chatMessageService.getActiveChatRoomSearchedMessageSubjectByChatId(this.chat.chat_id).subscribe(sm => {
      console.log(`${this.chat.chat_id} activeChatRoomSearchedMessages$ subject triggered`, sm);

      if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
        if (this.checkNewMessageIsRealMessage(sm)) {
          if (this.enableAutoScroll?.enabled) {
            if (this.checkNewMessageIsSentByOthers(sm)) {
              this.scrollToBottom = true;
              this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
            } else {
              // this.scrollToBottom = true;
              this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
            }
          } else {
            if (this.checkNewMessageIsSentByOthers(sm)) {
              this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
            } else {
              this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: true, isNewMsgAction: false })
            }
  
            // this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
            // this.updateIsEnableChatroomAutoScroll(null)
          }
        }
      }

      // console.log('this.isLoadingHistory', this.isLoadingHistory); 
      // console.log('this.messages.length', _.cloneDeep(this.messages));
      // load previous searched messages
      let allSearchMsgs = this._chatMessageService.getAllChatSearchedMessageUnderChat(this.chat.chat_id, this.messages.length)
      
      // console.log('allSearchMsgs????', allSearchMsgs);
      // console.log(this.chat);

      /* 
        TODO: check 'newMessageCount' in this.chat to set this.isAtChatLatest = true
        to load latest message(unread message) by calling loadHistoryApi(false) 
      */
    //  console.log('allSearchMsgs', allSearchMsgs);
      this.updateCurrentMessages(allSearchMsgs);
      this.updateLastMessagePointer(true);
      // this.updateLastMessagePointer();
    });

    if (!this.targetMessage.isFilterPointer) {
      // is in searching mode
      this.messages = [this.targetMessage];
    } else {
      // is in filtering mode, no need to prepare flash animation for the message 
      this.targetMessageWasFlashed = true;
      console.log('filtering mode targetMessageWasFlashed', this.targetMessageWasFlashed);
      // this.isAtChatLatest = true;
    }
    
    // this.updateFirstMessagePointer();
    this.loadHistoryApi(true, true, this.targetMessage, () => {
      this.isSearchedPrevMessage = true;
      this.tryToClearTargetMessage();
    });

    // if (this.targetMessage.timestamp_from) {
    if (this.targetMessage.isFilterPointer) {
      // filtering mode in multi chat room 
      return;
    }

    this.loadHistoryApi(false, true, this.targetMessage, () => {
      this.isSearchedNextMessage = true;
      this.tryToClearTargetMessage();
    });
  }

  /**
   * Load history via API
   *
   * If loading history already and not in init mode, return.
   * Base on isBackward loading or not, check its edging status, find target timestamp, and update relative message pointer
   *
   * After received messages, get only REAL messages by type.
   * Update {messages} and sort
   * Check if chat is at edge
   * Scroll to target message if neccessary
   *
   * @param {boolean} isBackward - if we are loading message backward or not
   * @param {boolean} [isInit] - if it is during init state
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  loadHistoryApi(isBackward: boolean, isInit?: boolean, targetMEssagePointer?: Message, callback?: Function, isLoopCall?: boolean): void {
    // start of loadHistoryApi
    console.log('start of loadHistoryApi this.isNeedStopAutoLoading', this.isNeedStopAutoLoading);
    // Loading already, and not init-ing, return
    if (this.isLoadingHistory && !isInit) {
      return;
    }

    let timestamp;
    if (isBackward) {
      if (this.autoLoadMessageTimer && this.isNeedStopAutoLoading && isLoopCall) {
        // this loadHistoryApi call should be in settimeout that binded to autoLoadMessageTimer
        this.clearAutoMsgHistoryApiLoaderStuff();
        console.log('<<<<< time to stop');
        return;
      }

      // loading backward but chat is at earliest, return
      if (this.isAtChatEarliest) {
        return;
      }

      if (this.targetMessage?.isFilterPointer) {
        timestamp = _.first(this.messages)?.timestamp || targetMEssagePointer?.timestamp || (_.now() / 1000).toString();
      
      } else {
        timestamp = _.first(this.messages).timestamp;
      }
      // If not init-ing, update pointer in order to scroll to it.
      if (!isInit) {
        console.log('load backwards--- [at top], next to updateFirstMessagePointer');
        if (this.isInMultiChatRoomMode) {
          console.log('loadHistoryApi updateFirstFilteredMessagePointer');
          this.updateFirstFilteredMessagePointer();
        } else {
          this.updateFirstMessagePointer();
        }
      }
    } else {
      // loading forward but chat is at latest, return
      if (this.isAtChatLatest) {
        // we are at chat's edge(latest message pointer), then re-init the current chat room with LoadHistory
        // this.exitSearchedMessageDisplayMode();
        return;
      }

      if (this.targetMessage?.isFilterPointer) {
        timestamp = _.last(this.messages)?.timestamp || targetMEssagePointer?.timestamp || (_.now() / 1000).toString();
      } else {
        timestamp = _.last(this.messages).timestamp;
      }
    }

    // Flag isLoadingHistory
    this.isLoadingHistory = true;
    // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})

    this._chatMessageService.chatLoadHistoryApi(
      this.chat.chat_id,
      timestamp,
      isBackward,
      (msgs) => {
        console.log('this.autoLoadMessageTimer<<<<<<', this.autoLoadMessageTimer, this.isNeedStopAutoLoading);
        if (this.autoLoadMessageTimer && this.isNeedStopAutoLoading && isLoopCall) {
          this.clearAutoMsgHistoryApiLoaderStuff();
          console.log('time to stop >>>>>');
          return;
        }
  
        // get REAL messages only
        let parsedMsgs = this._chatMessageService.parseSearchedMsgResultIntoMessages(msgs);

        // If only one message is loaded, it is either the earliest or the latest message in chat, we are at chat's edge.
        if (parsedMsgs.length <= 1) {
          if (isBackward) {
            this.isAtChatEarliest = true;
          } else {
            this.isAtChatLatest = true;
          }
        } else {
          if (this.targetMessage?.isFilterPointer) {
            // in multi-chat filtering mode
            // const realMessageTypes = [
            //   MessageTypeConstant.TEXT,
            //   MessageTypeConstant.ATTACHMENT,
            //   MessageTypeConstant.LOCATION,
            //   MessageTypeConstant.STICKER
            // ];
  
            const realMsg = _.filter(parsedMsgs, (m) => _.includes(this.realMessageTypes, _.toInteger(m.type)))
            console.log('realMsg', realMsg);
            if (realMsg.length <= 1) {
              if (!isBackward) {
                // no need to call loading latest message again
                this.isAtChatLatest = true;
              } 
            } else {
              // isDateTimeFiltering
              // if (this.targetMessage.timestamp_from) {
              //   console.log('need to checkd the timestamp_from', this.targetMessage.timestamp_from);
                
              //   console.log(_.map(_.cloneDeep(parsedMsgs), 'timestamp'));
              // }
            }
          }
        }

        // Append to {messages}
        this.messages = _.union(this.messages, parsedMsgs);
        // Uniq messages array by message_id (as we will received the original timestamp message in each API call)
        this.messages = _.uniqBy(this.messages, 'message_id');
        this.messages = _.sortBy(this.messages, 'timestamp');
        
        if (this.targetMessage.timestamp_from) {
          let msgsBeforeTimeFilterRange = _.cloneDeep(this.messages)
          console.log('timestamp_from filter before', msgsBeforeTimeFilterRange.length);
          // this.messages = _.remove(msgsBeforeTimeFilterRange, (m) => m.timestamp >= this.targetMessage.timestamp_from);

          // this.messages = _.filter(msgsBeforeTimeFilterRange, (m) => m.timestamp >= this.targetMessage.timestamp_from);
          msgsBeforeTimeFilterRange = _.filter(msgsBeforeTimeFilterRange, (m) => m.timestamp < this.targetMessage.timestamp_from);
          
          console.log('timestamp_from filter after', msgsBeforeTimeFilterRange.length);
          if (msgsBeforeTimeFilterRange.length) {
            // message timestame hit the timestamp_from
            console.log('message timestame hit the timestamp_from');
            this.isAtChatEarliest = true;
          }
        }

        // store all current searched messages for temporary using
        this._chatMessageService.storeChatSearchedMessage(this.messages, this.chat.chat_id);

        // Flag to stay at current position
        this.scrollToBottom = false;

        // Force update last message pointer in order to not show UNREAD MESSAGE bar
        this.updateLastMessagePointer(true);

        // If loading backward or during init, scroll to first message
        if (isBackward && !isInit) {
          console.log('loadHistoryApi updateFirstFilteredMessagePointer scrollToTargetMessage', this.firstMessagePointer);
          
          this.scrollToTargetMessage(this.firstMessagePointer);
        }
        if (isInit) {
          console.log('isInit', this.isMemberFiltering, this.targetMessageWasFlashed);
          if (!this.isMemberFiltering && !this.isDateTimeFiltering && !this.targetMessageWasFlashed) {
            this.scrollToTargetMessage(targetMEssagePointer);
          }
        }
        // Finished loading history
        // this.isLoadingHistory = false;
        // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})

        // Check if bottom right menu should display
        this.checkIfBottomRightMenuShouldDisplay();


        /* auto load message history by api */
        // if (isBackward && !isInit) {
          // atTop, load message backwards
          // console.log('api returned searched messages', parsedMsgs);
          console.log('this.isNeedStopAutoLoading', this.isNeedStopAutoLoading);

          if (this.isInMultiChatRoomMode && this.isMemberFiltering) {
            // this.checkIfNeedLoadMoreMessage(isBackward, isInit, timestamp, parsedMsgs);

            if (isBackward && !this.componentEnded) {
              this.checkIfNeedLoadMoreMessage(timestamp, parsedMsgs);
            } else {
              // load message is not backwards
              console.log('????????');
              this.isLoadingHistory = false;
            }
          } else {
            // clearTimeout(this.autoLoadMessageTimer);
            this.isLoadingHistory = false;
            // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})
          }
          
        // }

        if (callback) {
          callback();
        }
      },
      (err) => {

      },
      this.isInMultiChatRoomMode
    );
  }

  // checkIfNeedLoadMoreMessage(isBackward: boolean, isInit: boolean, timestamp: string, parsedMsgs: Message[]): void {
  checkIfNeedLoadMoreMessage(timestamp: string, parsedMsgs: Message[]): void {
    // this.checkIfMessageTimestampIsValid(timestamp);
    
    // if (isBackward && !isInit && !this.componentEnded) {
    // if (isBackward && !this.componentEnded) {

      if (!this.checkIfMessageTimestampIsValid(timestamp)) {
        // hit the earlier date
        this.clearAutoMsgHistoryApiLoaderStuff();
        this.isAtChatEarliest = true;
        return;
      }
      // if (!filteredSearchedMsgs.length && !this.isNeedStopAutoLoading && this.checkIfMessageTimestampIsValid(timestamp)) {
      // if (!filteredSearchedMsgs.length && !this.isNeedStopAutoLoading) {
      const selectedIds = _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id');
      let filteredSearchedMsgs = this._chatMessageService.filterMessageBySelectedUser(parsedMsgs, selectedIds, this.chat);
      console.log('filteredSearchedMsgs', filteredSearchedMsgs);

      if (!filteredSearchedMsgs.length) {
        this.isNeedStopAutoLoading = false
        console.log('ready to load again');
        
        clearTimeout(this.autoLoadMessageTimer);
        this.autoLoadMessageTimer = setTimeout(() => {
          this.isLoadingHistory = false;
          // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})
          this.loadHistoryApi(true, false, this.targetMessage, null, true);
        }, 200);
      } else {
        this.isLoadingHistory = false;
        // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})
      }
    // } else {
    //   // load message is not backwards
    //   console.log('????????');
    //   this.isLoadingHistory = false;
    // }

  }

  checkIfMessageTimestampIsValid(msgTimestamp: string): boolean {
    const chatCreateDate = this.chat.create_date;
    const membersCreateDate = _.map(this.chat.members, (m) => {
      const uc = this._userContactService.getUserContactByUserId(m)
      return uc ? uc.create_date : this._timestampService.getNowSecondString();
    })
    const earliestCreateDate = _.min([chatCreateDate, ...membersCreateDate]);

    console.log('earliestCreateDate', earliestCreateDate);

    if (msgTimestamp && !this._timestampService.checkIfTimeCorrectOrder(earliestCreateDate, msgTimestamp)) {
      // timestamp pointer is earlier than chat create date
      return false;
    }

    return true;
  }

  /**
   * Exit search message display mode
   * (Re-enter chat room from chat)
   *
   * @memberof ChatRoomComponent
   */
  exitSearchedMessageDisplayMode(isQuickTravel?: boolean, isNeedClearPrevMessageFirst?: boolean): void {
    this.targetMessageWasFlashed = false
    
    this.unsubscribeSearchingModeBehaviorSubject();
    this.removeChatSearchedMessageByChatId();
    this.exitSearchModeAndGoToChat.emit({chat: this.chat, isQuickTravel: isQuickTravel, isNeedClearPrevMessageFirst: isNeedClearPrevMessageFirst});
  }

  /**
   * Update First message pointer
   *
   * @memberof ChatRoomComponent
   */
  updateFirstMessagePointer(filteredMessage?: Message[]): void {
    if (!filteredMessage) {
      this.firstMessagePointer = _.first(this.messages);
      return;
    }

    this.firstMessagePointer = _.first(filteredMessage);
    console.log('updateFirstMessagePointer', this.firstMessagePointer);
  }

  /**
   * Update Last message pointer
   *
   * @param {boolean} [forceConfig] - Force updating of last message pointer
   * @memberof ChatRoomComponent
   */
  updateLastMessagePointer(forceConfig?: boolean): void {
    if (this.chat.newMessageCount == 0 || forceConfig) {
      this.lastMessagePointer = _.last(this.messages);
    }
  }

  /**
   * Get first unread message by unread count
   *
   * @returns {Message} - the first unread message
   * @memberof ChatRoomComponent
   */
  getFirstUnreadMessageByUnreadCount(): Message {
    return this.messages[(this.messages.length - 1) - this.chat.newMessageCount];
  }

  /**
   * Update last message pointer by chat unread count (use during init)
   *
   * @memberof ChatRoomComponent
   */
  updateLastMessagePointerByUnreadCount(): void {
    this.lastMessagePointer = this.getFirstUnreadMessageByUnreadCount();
  }

  /**
   * Common handler for updating messages view
   *
   * 1. If {messages} is provided, use it. If not, get the latest active chatroom messages
   * 2. Update current messages object.
   * 3. If it was loading history, scroll to {firstMessagePointer} and udpate first message pointer
   * 4. If initial message reads are not sent, send it with a small 2 seconds delay
   *
   * @param {Message[]} [messages] - target messages (Optional)
   * @memberof ChatRoomComponent
   */
  handleMessageSubejctUpdate(): void {
    let latestMessages = this._chatRoomService.loadLocalChatHistory(this.chat.chat_id, this.messages.length);

    this.updateCurrentMessages(latestMessages);

    if (this.isLoadingHistory) {
      this.isLoadingHistory = false;
      // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})

      // console.log('this.firstMessagePointer', _.cloneDeep(this.firstMessagePointer));
      this.scrollToTargetMessage(this.firstMessagePointer);
      this.updateFirstMessagePointer();
    }

    // Send initial read after 2 seconds if it has not been sent yet
    if (!this.isSentInitialRead) {
      this.isSentInitialRead = true;
      setTimeout(() => {
        if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
          this.sendRead();
        }
      }, 2000);
    }
  }

  /**
   * Update current messages
   *
   * @param {any} messages - target messages
   * @memberof ChatRoomComponent
   */
  updateCurrentMessages(messages): void {
    this.messages = messages;
  }

  /**
   * All Scroll events (chrome, firefox, ie)
   *
   * @param {*} event - scroll event
   * @memberof ChatRoomComponent
   */
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'ie');
  }

  // handle manual scroll event 
  onMessagesScroll(event): void {
    // this.mouseWheelFunc(event, '');
  }

  storePrevScrollPos(): void {
    if (!this.messagesElement) {
      return;
    }
      
    let element = this.messagesElement.nativeElement;
    this.chat.scrollTop = element.scrollTop;
    // console.log('this.chat.scrollTop', this.chat.scrollTop);
  }

  private debouncedStoreCurrentScrollPos = _.debounce(this.storePrevScrollPos, 200);

  /**
   * Common function when mouse scrolled
   *
   * 1. If user is re-logging in, return.
   * 2. Check if scroll position is at the top, if yes, load history
   * 3. Call common chatroom activities handler
   *
   * @param {any} event - Mouse scroll event
   * @param {any} from - browser type
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  mouseWheelFunc(event, from): void {
    if (this.reloginAction) {
      return;
    }

    if (this.isShowNoMessageOverlay) {
      return;
    }

    let element = this.messagesElement.nativeElement;
    if (this.isInMultiChatRoomMode) {
      this.debouncedStoreCurrentScrollPos();
    }
    
    this.scrollToBottom = (element.scrollHeight - element.scrollTop) === element.clientHeight;

    if (this.isInMultiChatRoomMode) {
      if (this.enableAutoScroll?.enabled) {
        if (this.scrollToBottom) {
          this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: false, isNewMsgAction: true })
        }
      }
    }

    // If scrolled to bottom, and is in searched message view mode, load history via API
    if (this.scrollToBottom) {
      if (this.targetMessage) {
        // will not loading frontwards message in date time filtering mode in multi chat room
        
        // const needLoadMsgFrontwards = !this.targetMessage.timestamp_from;
        const needLoadMsgFrontwards = !this.targetMessage.isFilterPointer;

        if (needLoadMsgFrontwards) {
          this.loadHistoryApi(false);
        }
      }
    }

    let atTop = element.scrollTop == 0;
    if (atTop && event.wheelDelta > 0 && !this.isLoadingHistory) {
      this.isShowFloatingDate = false;
      // If scrolled to top, check if we should load history using API or AMQP
      if (this.targetMessage) {
        this.loadHistoryApi(true);
      } else {
        this.loadHistory();
      }
    }

    // Check if bottom right menu should display
    this.checkIfBottomRightMenuShouldDisplay();

    // Send read when user scroll chat room and update chatroom activities
    this.onChatroomActivities();

    // Handle floating date label
    this.setFloatingDate();
  }

  /**
   * Toggle bottom right menu display
   *
   * If scrolled up {bottomRightMenuDisplayTriggerHeight}px, display the menu
   *
   * @memberof ChatRoomComponent
   */
  checkIfBottomRightMenuShouldDisplay(): void {
    // If we are in searched message display mode, always show bottom right menu
    if (!this.isMemberFiltering && !this.isDateTimeFiltering && this.targetMessage) {
      this.shouldBottomRightMenuDisplay = true;
      return;
    }

    let element = this.messagesElement.nativeElement;

    if ((element.scrollHeight - element.scrollTop - element.clientHeight) > this.bottomRightMenuDisplayTriggerHeight) {
      this.shouldBottomRightMenuDisplay = true;
    } else {
      this.shouldBottomRightMenuDisplay = false;
    }
  }

  triggerBottomRightMenuShouldDisplay(action?: string): void {
    switch (action) {
      case 'showScrollToBottom':
        if (!this.shouldBottomRightMenuDisplay) {
          this.shouldBottomRightMenuDisplay = true;
        } 

        break;
    }
  }

  setFloatingDate(): void {
    let messageWrapper = this.messagesElement.nativeElement;
    let messageWrapperBoundingRect = messageWrapper.getBoundingClientRect();
    let comparingTop = messageWrapperBoundingRect.top;

    let allDateRows = document.getElementsByClassName('message-date');

    if (this.isInMultiChatRoomMode) {
      allDateRows = messageWrapper.getElementsByClassName('message-date');
    }
    
    let targetIndex = 0;
    for (let index = 0; index < allDateRows.length; index++) {
      let dateRowBoundingRect = allDateRows[index].getBoundingClientRect();
      if (dateRowBoundingRect.top > comparingTop) {
        break;
      }
      targetIndex = index;
    }

    if (allDateRows[targetIndex]) {
      this.floatingDateValue = allDateRows[targetIndex].innerHTML;
      this.isShowFloatingDate = true;
    }
    clearTimeout(this.scrollingFloatDateInterval);
    this.scrollingFloatDateInterval = setTimeout(() => {
      this.isShowFloatingDate = false;
    }, 1000);
  }

  /**
   * Scroll to target message by finding its id (message_id)
   *
   * @param {Message} message - target scroll-to message
   * @memberof ChatRoomComponent
   */
  scrollToTargetMessage(message: Message): void {
    if (message) {
      let targetMsg = document.getElementById(message.message_id);
      // console.warn('scrolling to ' + message.body);
      if (targetMsg) {
        targetMsg.scrollIntoView();
        
        if (this.isInMultiChatRoomMode) {
          setTimeout(() => {
            this.storePrevScrollPos();
          }, 10);
        }

        if (this.targetMessage && !this.targetMessageWasFlashed) {
          this.updateChatDraggableState.emit({chatId: this.chat.chat_id, disabledDrag: true})

          setTimeout(() => {
            targetMsg.className += ' flash-once';
            
            this.targetMessageWasFlashed = true
          }, 1000);

          let flashResetSec = 5000;
          if (this.isInMultiChatRoomMode) {
            flashResetSec = 2000;
          }

          setTimeout(() => {
            targetMsg.className = 'tn-chat-message';
            this.updateChatDraggableState.emit({chatId: this.chat.chat_id, disabledDrag: false})
          }, flashResetSec);
        }
      }
    }
  }

  /**
   * On bottom right menu quick travel click
   *
   * 1. If there are unread messages, scroll to the first unread message.
   * 2. If not, scroll to bottom
   * 3. hide the menu
   *
   * @memberof ChatRoomComponent
   */
  onQuickTravelClick(event: MouseEvent): void {
    if (this.isInMultiChatRoomMode) {
      event.stopPropagation();
    }

    // When clicked quick travel, exit search display mode
    if (this.targetMessage) {
      if (this.isInMultiChatRoomMode) {
        if (this.targetMessage.isFilterPointer) {
          // if in filtering mode(isFilterPointer = true), onQuickTravelClick just scrollToBottom, 
          // no need to exitSearchedMessageDisplayMode
          this.scrollToBottom = true;
          this.shouldBottomRightMenuDisplay = false;
          return;
        }
      }

      this.exitSearchedMessageDisplayMode(true, true);
    }

    if (this.chat.newMessageCount) {
      this.scrollToTargetMessage(this.getFirstUnreadMessageByUnreadCount());
    } else {
      this.scrollToBottom = true;
    }
    this.shouldBottomRightMenuDisplay = false;
  }

  /**
   * Load chat history, set flag when loading
   *
   * Use custom callback function for loadHistory to differentiate "Load history" and "New message received".
   *
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  loadHistory(): void {
    this.isLoadedInitHistory = true;
    // Loading history already or at chat's edge, return.
    if (this.isLoadingHistory || this.isAtChatEarliest) {
      return;
    }
    this.isLoadingHistory = true;
    // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})

    if (this.isInMultiChatRoomMode) {
      this.updateFirstFilteredMessagePointer();
    } else {
      this.updateFirstMessagePointer();
    }
    // this.updateFirstMessagePointer();

    if (
      this._chatMessageService.checkIfTimestampIsEarliestMessage(
        this.chat.chat_id,
        this.firstMessagePointer ? this.firstMessagePointer.timestamp : null,
        this.multiChatFilterOptions ? _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id') : null
      )
    ) {
      this._chatRoomService.loadChatHistory(
        this.chat.chat_id,
        (bodyLength) => {
          // If number of messages returned is 1, we are at chat's edge.
          if (bodyLength <= 1) {
            this.isAtChatEarliest = true;
          }

          this.handleMessageSubejctUpdate();
        },
        this.loadHistorySize ? this.loadHistorySize : null
      );
      this.loadHistorySize = null
    } else {
      this.handleMessageSubejctUpdate();
    }
  }

  /**
   * On input key down, call common chatroom activities handler
   *
   * @memberof ChatRoomComponent
   */
  inputKeyDown(): void {
    // Send read when user type in textarea
    this.onChatroomActivities();
  }

  inputKeyUp(event: KeyboardEvent): void {
    if (this.messageInputTextarea) {
      // console.warn(event);
      let height = Math.max(this.inputTextAreaMaxHeight, this.messageInputTextarea.nativeElement.scrollHeight);
      height = Math.min(height, window.innerHeight * 0.45);

      if (this.inputMessage.length == 0) {
        height = this.inputTextAreaMaxHeight;
        this.cancelMentionMode();
        this.cancelHashtagMode();
        this.mentionedMembers = [];
      }

      if (this.isAutoAdjustHeight) {
        this.messageInputTextarea.nativeElement.setAttribute('style', `height:${height}px !important`);
      }

      // console.log(event);

      // Check @
      if ((event.key == '@' || event.key == '2' || event.shiftKey) && this.checkIfMentionShouldInitiate()) {
        this.onMentionInitiate();
      }

      // Check #
      if ((event.key == '#' || (event.key == '3' && event.shiftKey)) && this.checkIfHashtagShouldInitiate()) {
        this.onHashtagInitiate();
      }

      // Check esc
      if (event.keyCode == 27) {
        this.cancelMentionMode();
        this.cancelHashtagMode();
      }

      // Check space
      if (event.keyCode == 32) {
        this.cancelMentionMode();
        this.cancelHashtagMode();
      }

      if (this.isInMentionMode) {
        this.updateMentionSelectionList();
      }

      if (this.isInHashtagMode) {
        this.updateHashtagSelectionList();
      }

      this.tryToCancelMentionHashtag();
    }
  }

  inputMouseClick(): void {
    this.tryToCancelMentionHashtag();
  }

  /**
   * On input key "Enter", handle SHIFT_ENTER_TO_SEND config to check if we should send the message
   *
   * @param {boolean} isWithShiftKey - is "Enter" pressed with shite key
   * @returns {boolean} - If this is a valid input and should update input box or not
   * @memberof ChatRoomComponent
   */
  inputKeyEnter(isWithShiftKey: boolean): boolean {
    if (this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.CLICK_TO_SEND_ONLY) {
      // do something else
      return true;
    }

    // check if mentioning / hashtaging
    if (this.isInMentionMode) {
      // do something else
      return true;
    }

    if (this.isInHashtagMode) {
      // do something else
      return true;
    }

    if (this._utilitiesService.checkIfKeyEnterShouldSend(isWithShiftKey)) {
      this.send();
      return false;
    }
    return true;
  }

  /**
   * Resetting all message input / replying
   *
   * @memberof ChatRoomComponent
   */
  resetAllInputs(): void {
    this.inputMessage = '';

    const editor = this.editableDiv?.nativeElement;

    if (this.isEnableMarkdownMessageInput && editor) {
      editor.innerText = ''
      this.updateHtmlInputDisplay();
    }

    if ('draft' in this.chat) {
      if (!this.isEnableMarkdownMessageInput) {
        this.inputMessage = this.chat.draft
      } else {
        if (editor) {
          editor.innerText = this.chat.draft
          this.updateHtmlInputDisplay();
        }
      }
    }

    this.mentionedMembers = [];
    // this.whisperingTarget = null;

    // this.replyingMessage = null;
    this.replyingMessage = this._chatRoomService.replyingMessage;
    this._chatRoomService.updateReplyingMessage(null) // reset replying messaged in chat room service

    this.annotatingMessage = null;

    this.isToggledEncryptedMessage = false;

    if (!this.isEnableMarkdownMessageInput) {
      if (this.messageInputTextarea) {
        this.messageInputTextarea.nativeElement.style.height = `${this.inputTextAreaMaxHeight}px`;
      }
    } else {
      if (this.editableDiv) {
        this.editableDiv.nativeElement.style.height = `${this.inputTextAreaMaxHeight}px`;
      }
    }
  }

  saveTextMessageDraft(): void {
    if (this.isEnableMarkdownMessageInput) {
      const editor = this.editableDiv?.nativeElement;

      if (editor) {
        if (editor.innerText !== '') {
          this.chat.draft = editor.innerText
          // console.log('saveTextMessageDraft', this.chat.draft);
        } else {
          this.resetCurrentChatDraft();
        }
      } else {
        this.resetCurrentChatDraft();
      }
      
      return;
    }

    if (this.inputMessage !== '') {
      this.chat.draft = this.inputMessage
    } else {
      this.resetCurrentChatDraft();
    }
  }

  /**
   * Resetting all draft for current chat
   *
   * @memberof ChatRoomComponent
   */
  resetCurrentChatDraft(): void {
    if (!('draft' in this.chat)) {
      return
    }

    delete this.chat.draft
  }

  /**
   * Send message via AMQP, scroll container to bottom
   *
   * @memberof ChatRoomComponent
   */
  send(): void {
    if (this.isEnableMarkdownMessageInput && !this.annotatingMessage) {
      const editor = this.editableDiv.nativeElement;
      if (editor.innerText?.trim().length > 0) {
        this.scrollToBottom = true;
        this.sendMessageHub(MessageTypeConstant.TEXT, editor.innerText);
        this.resetCurrentChatDraft();
        this.resetAllInputs();
      }

      return 
    }

    if (this.inputMessage.length > 0) {
      if (this.annotatingMessage) {
        this.sendMessageAnnotation(this.annotatingMessage, this.inputMessage, this.chatRoomMode == CHAT_ROOM_MODE.ANNOTATE ? 1 : 0);
        this.resetCurrentChatDraft();
        this.resetAllInputs();
        
        if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
          this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
        }
        return;
      }

      this.scrollToBottom = true;
      // this.updateIsEnableChatroomAutoScroll({ isMsgSendAction: true, isNewMsgAction: false })
      this.sendMessageHub(MessageTypeConstant.TEXT, this.inputMessage);
      this.resetCurrentChatDraft();
      this.resetAllInputs();
    }
  }

  /**
   * Send messaga hub
   *
   * Avoid repeatedly passing special vairables for each type
   * Simplify it here
   *
   * @param {number} messageType - message type
   * @param {*} content - content (text / files / locationBody)
   * @memberof ChatRoomComponent
   */
  sendMessageHub(messageType: number, content: any): void {
    // When sending new message, exit search display mode
    if (this.targetMessage) {
      this.exitSearchedMessageDisplayMode();
    }

    this._chatRoomService.sendMessgeHub(
      messageType,
      this.chat.chat_id,
      content,
      this.whisperingTarget,
      this.replyingMessage,
      this.isToggledEncryptedMessage,
      () => {
        this.updateLastMessagePointer();
      },
      this.mentionedMembers,
      this.isImportant ? 1 : 0,
      this.isSms
    );
  }

  @ViewChild('editableDiv', { static: false }) editableDiv: ElementRef;
  private undoStack: string[] = [''];
  private redoStack: string[] = [];
  // content: string = '';
  // formattedContent: string = '';

  // get the cursor position from .editor start
  getCursorPosition(parent, node, offset, stat) {
    if (stat.done) return stat;

    let currentNode = null;
    if (parent.childNodes.length == 0) {
      stat.pos += parent.textContent.length;
    } else {
      for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
        currentNode = parent.childNodes[i];
        if (currentNode === node) {
          stat.pos += offset;
          stat.done = true;
          return stat;
        } else if (currentNode.nodeName === 'BR') {
          // Consider <br> as a single character
          stat.pos += 1;
        } else {
          this.getCursorPosition(currentNode, node, offset, stat);
        }
      }
    }
    return stat;
  }

  //find the child node and relative position and set it on range
  setCursorPosition(parent, range, stat) {
    if (stat.done) return range;

    if (parent.childNodes.length == 0) {
      if (parent.textContent.length >= stat.pos) {
        range.setStart(parent, stat.pos);
        stat.done = true;
      } else {
        stat.pos = stat.pos - parent.textContent.length;
      }
    } else {
      for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
        // let currentNode = parent.childNodes[i];
        // this.setCursorPosition(currentNode, range, stat);

        let currentNode = parent.childNodes[i];
        if (currentNode.nodeName === 'BR') {
            if (stat.pos === 0) {
                range.setStartAfter(currentNode);
                stat.done = true;
            } else {
                stat.pos -= 1;
            }
        } else {
            this.setCursorPosition(currentNode, range, stat);
        }
      }
    }
    return range;
  }

  onCompositionStart(event) {
    event.target.composing = true
  }
  
  onCompositionEnd(event) {
    if(!event.target.composing) return;
    event.target.composing = false
  }

  restoreCursor(): void {
    document.execCommand('selectAll', false, null);
    document.getSelection().collapseToEnd();

    // const sel = window.getSelection();
    // const range = document.createRange();
    // const editor = this.editableDiv.nativeElement;

    // if (editor.childNodes.length > 0) {
    //   range.setStart(editor.childNodes[0], 0);
    //   range.collapse(true);

    //   sel.removeAllRanges();
    //   sel.addRange(range);
    // }
  }

  private THROTTLE_THRESHOLD = 250;
  private throttleUpdateUndoStack = _.throttle(this.updateUndoStack, this.THROTTLE_THRESHOLD);

  updateUndoStack(htmlContent) {
    // const currentState = this.editableDiv.nativeElement.innerHTML;
    this.undoStack.push(htmlContent);
    // console.log('this.undoStack', this.undoStack);
  }

  undo(): void {
    if (this.undoStack.length > 0) {
      // this.throttleUpdateUndoStack();
      const editor = this.editableDiv.nativeElement;

      // const currentState = editor.innerHTML;
      const currentState = editor.innerText;

      this.redoStack.push(currentState);
      const previousState = this.undoStack.pop();
      // console.log('undo previousState innerText', previousState);
      // this.editableDiv.nativeElement.innerHTML = previousState || '';
      editor.innerHTML = this.parseHTML(_.last(this.undoStack) || '');

      this.restoreCursor();
    }
  }

  redo(): void {
    if (this.redoStack.length > 0) {
      const editor = this.editableDiv.nativeElement;

      // const currentState = editor.innerHTML;
      const currentState = editor.innerText;
      this.undoStack.push(currentState);

      const nextState = this.redoStack.pop();
      // editor.innerHTML = nextState || '';
      editor.innerHTML = this.parseHTML(nextState || '');
      // editor.innerHTML = this.parseHTML(_.last(this.redoStack));

      this.restoreCursor();
    }
  }

  parseHTML(text) {
    //use (.*?) lazy quantifiers to match content inside
    return (
      text
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}/gm, '<span class="reset-style">~</span><span class="del-content">$1</span><span class="reset-style">~</span>') // strikethrough
        .replace(/\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}/gm, '<span class="reset-style">*</span><span class="strong-content">$1</span><span class="reset-style">*</span>') // bold
        .replace(/\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1}/gm, '<span class="reset-style">_</span><span class="em-content">$1</span><span class="reset-style">_</span>') // italic
        
        
        // .replace(/(?<!\S)([\*\_]*)\~{1}([^\s][^\~]*[^\s]|[^\s]{1})\~{1}([\*\_]*)(?!\S)/gm, '$1<span class="reset-style">~</span><span class="del-content"> $2 </span><span class="reset-style">~</span>$3') // strikethrough
        // .replace(/(?<!\S)([\~\_]*)\*{1}([^\s][^\*]*[^\s]|[^\s]{1})\*{1}([\~\_]*)(?!\S)/gm, '$1<span class="reset-style">*</span><span class="strong-content"> $2 </span><span class="reset-style">*</span>$3') // bold
        // .replace(/(?<!\S)([\*\~]*)\_{1}([^\s][^\_]*[^\s]|[^\s]{1})\_{1}([\*\~]*)(?!\S)/gm, '$1<span class="reset-style">_</span><span class="em-content"> $2 </span><span class="reset-style">_</span>$3') // italic
        // .replace(/(<span.*>) (.*) (<\/span>)/g, '$1$2$3')
        // handle special characters
        // .replace(/\n/gm, "<br>")
    );
  }

  updateHtmlInputDisplay() {
    const editor = this.editableDiv.nativeElement;

    // this.undoStack.push(editor.innerHTML); // Save current state for undo
    // this.throttleUpdateUndoStack(editor.innerHTML);
    this.throttleUpdateUndoStack(editor.innerText);

    this.redoStack = []; // Clear redo stack on new input
    //get current cursor position
    const sel = window.getSelection();
    const node = sel.focusNode;
    const offset = sel.focusOffset;
    // console.log('offset', offset);
    const pos = this.getCursorPosition(editor, node, offset, { pos: 0, done: false });
    // if (offset === 0) pos.pos += 0.5;
    // console.log('position', pos);
    // console.log('editor.innerText\n', editor.innerText);
    editor.innerHTML = this.parseHTML(editor.innerText);


    // if (editor.innerHTML.trim() === '') {
    if (editor.innerHTML === '') {
      // Insert a <br> as a placeholder to keep the cursor visible
      editor.innerHTML = '<br>';
      pos.pos = 0;  // Reset the cursor position to the start
    } 

    // restore the position
    sel.removeAllRanges();
    const range = this.setCursorPosition(editor, document.createRange(), {
      pos: pos.pos,
      done: false,
    });

    range.collapse(true);
    sel.addRange(range);
  }

  handleMenuUndoRedo(event) {
    if (event.inputType === 'historyUndo') {
      // Prevent native undo
      event.preventDefault();
      this.undo();
    } else if (event.inputType === 'historyRedo') {
      // Prevent native redo
      event.preventDefault();
      this.redo();
    }
  }

  // Triggered on user input
  onInput(event: any) {
    if(event.target.composing) return;
    this.updateHtmlInputDisplay();
  }

  insertLineBreak(): void {
    // const sel = window.getSelection();
    // const range = sel.getRangeAt(0);
    // // console.log('range', range);
    
    // // Create a new <br> element
    // const br = document.createElement('br');
    
    // // Insert the <br> at the current cursor position
    // range.deleteContents();
    // range.insertNode(br);
    
    // // Move the cursor to the start of the new line
    // range.setStartAfter(br);
    // range.setEndAfter(br);

    // sel.removeAllRanges();
    // sel.addRange(range);
    document.execCommand('insertLineBreak')

    const editor = this.editableDiv.nativeElement;
    editor.scrollTop = editor.scrollHeight; // auto scroll when hit the max-height
  }


  insertNewLine() {
    let sel, range, html;
    sel = window.getSelection();
    range = sel.getRangeAt(0);
    range.deleteContents();
      
    // Do not always works
    let textNode = document.createTextNode("\n");
    
    // Always works
    // var textNode = document.createTextNode("\n\u200b");
      
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  handleBackspace(event): void {
    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    const editor = this.editableDiv.nativeElement;

    // If the editor is empty after Backspace
    // if (editor.innerText.trim() === '') {
    //   event.preventDefault();  // Prevent default behavior

    //   // Insert a <br> to make sure the contenteditable has focusable content
    //   editor.innerHTML = '<br>';

    //   // Set cursor at the start
    //   const newRange = document.createRange();
    //   newRange.setStart(editor.childNodes[0], 0);
    //   newRange.collapse(true);

    //   sel.removeAllRanges();
    //   sel.addRange(newRange);
    // }

    // if (editor.innerText.trim() === '' || editor.innerHTML === '<br>') {
    if (editor.innerHTML === '<br>') {
      event.preventDefault();  // Prevent default behavior
  
      // Ensure it contains exactly one <br>
      // editor.innerHTML = '<br>';
  
      // Set cursor at the start
      // const sel = window.getSelection();
      // const range = document.createRange();
      // range.setStart(editor.childNodes[0], 0);
      // range.collapse(true);
  
      // sel.removeAllRanges();
      // sel.addRange(range);
      // console.log('handleBackspace', range);

      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToStart();
    }
  }

  onEditableDivInputBlur(): void {
    this.saveTextMessageDraft();

    this.undoStack= [''];
    this.redoStack= [];
  }

  onEditableDivInputKeyEnter(isWithShiftKey: boolean) {
    event.preventDefault();
    this.insertLineBreak();
  }

  onKeydown(event: any): void {
    this.onChatroomActivities();

    // console.log(event);
    if ((event.key === 'z' && event.ctrlKey) || (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey)) {
      event.stopImmediatePropagation();
      event.preventDefault();
      // console.log('undo', );
      this.undo();
    } else if ((event.key === 'y' && event.ctrlKey) || (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey)) {
      event.stopImmediatePropagation();
      event.preventDefault();
      // console.log('redo');
      this.redo();
    } 
    // else if (event.key === 'Enter' && event.shiftKey) {
    //   event.preventDefault();
    //   this.insertLineBreak();
    //   // this.insertNewLine();
    // } 
    else if (event.key === 'Backspace') {
      this.handleBackspace(event);
    }
  }

  updateMarkdownMessageWithSelectedUser(user) {
    const editor = this.editableDiv.nativeElement;
    
    let lastIndex = editor.innerText.lastIndexOf('@');
    let original = editor.innerText.substr(0, lastIndex + 1);
    editor.innerText = original + user.name + ' ';

    this.updateHtmlInputDisplay();
    // this.restoreCursor();

    this.focusOnInputTextarea();

    this.mentionedMembers.push({
      length: user.name.length,
      location: lastIndex + 1,
      user_id: user.user_id,
      name: user.name
    });
  }

  // Check @ mention for editableDiv
  checkMentionAndHashtagShouldInitiateForEditableDiv(char: string): boolean {
    const editor = this.editableDiv.nativeElement;
    if (editor.innerText.length == 1 && editor.innerText[0] == char) {
      return true;
    }
    let lastChars = editor.innerText.substr(editor.innerText.length - 2);
    if (lastChars == ` ${char}` || lastChars == `\n${char}`) {
      return true;
    }

    // check empty string before '@' & any char after '@'
    let regex = /s*@(\S+)/ 
    if (regex.exec(editor.innerText)) {
      return true;
    }

    return false;
  }

  onEditableDivInputKeyUp(event) {
    if (this.editableDiv) {
      // console.warn(event);
      let height = Math.max(this.inputTextAreaMaxHeight, this.editableDiv.nativeElement.scrollHeight);
      height = Math.min(height, window.innerHeight * 0.45);

      const editor = this.editableDiv.nativeElement;

      if (editor.innerText.length == 0) {
        height = this.inputTextAreaMaxHeight;
        this.cancelMentionMode();
        this.cancelHashtagMode();
        this.mentionedMembers = [];
      }

      if (this.isAutoAdjustHeight) {
        this.editableDiv.nativeElement.setAttribute('style', `height:${height}px !important`);
      }

      // console.log(event);

      // Check @
      if ((event.key == '@' || event.key == '2' || event.shiftKey) && this.checkIfMentionShouldInitiate()) {
        this.onMentionInitiate();
      }

      // Check #
      if ((event.key == '#' || (event.key == '3' && event.shiftKey)) && this.checkIfHashtagShouldInitiate()) {
        this.onHashtagInitiate();
      }

      // Check esc
      if (event.keyCode == 27) {
        this.cancelMentionMode();
        this.cancelHashtagMode();
      }

      // Check space
      if (event.keyCode == 32) {
        this.cancelMentionMode();
        this.cancelHashtagMode();
      }

      /* '*', '_', '~' */
      if (event.keyCode == 56 || event.keyCode == 189 || event.keyCode == 192) {
        this.cancelMentionMode();
        this.cancelHashtagMode();
      }

      if (this.isInMentionMode) {
        this.updateMentionSelectionList();
      }

      if (this.isInHashtagMode) {
        this.updateHashtagSelectionList();
      }

      this.tryToCancelMentionHashtag();
    }
  }

  sendMessageAnnotation(annotateMsg: Message, inputMessage: string, type: number): void {
    this._chatRoomService.sendAnnotationMessgeHub(type, inputMessage, annotateMsg, this.chat.chat_id)
  }

  onClickChatroomAction() {
    if (this.isInMultiChatRoomMode) {
      if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
        this.showSendBar();
        // setTimeout(() => {
        //   this.focusOnInputTextarea();
        // }, 0);
      }
    }

    this.onChatroomActivities();
  }

  onOpenChatroomMenu(event): void {
    if (this.isInMultiChatRoomMode) {
      event.stopPropagation();
    }
  }

  /**
   * Common chatroom activities handler
   *
   * 1. Hide context menu
   * 2. If user is re-logging in, return
   * 3. Reset idle timeout for CONFIDENTIAL chat
   * 4. If container is scrolled to bottom, send READ
   *
   * @returns
   * @memberof ChatRoomComponent
   */
  onChatroomActivities() {
    this._contextMenuService.hideContextMenu();
    if (this.reloginAction) {
      return;
    }
    this.resetIdleTimeout(true);
    // Only send read if messages container is scrolled to bottom
    if (this.scrollToBottom) {
      if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
        this.sendRead();
      }
    }
  }

  /**
   * Send all reads under chat
   *
   * @memberof ChatRoomComponent
   */
  sendRead(): void {
    // check if multi account login is enabled
    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      if (!this._socketService.isTargetAccountWebSocketConnected()) {
        return
      }
    } else {
      // Do not send message read if the WebSocket is not connected
      if (!this._socketService._isConnected) {
        return;
      }
    }
    
    // Do not send read if we are in searched message mode
    if (this.targetMessage) {
      return;
    }
    if (this.isAckToRead) {
      return;
    }
    this._chatRoomService.sendBatchReadUnderChat(this.chat.chat_id);
  }

  // Chat Title Click
  /**
   * Clicked on chat title
   *
   * @memberof ChatRoomComponent
   */
  onChatTitleClick(): void {
    if (this.isShowNoMessageOverlay) {
      return;
    }

    this._loggerService.log('Clicked chat room title');
    if (this.isGroupChat) {
      this.toggleChatGroupSettings();
    } else {
      this.onUserAvatarClick(this.chat.chatTarget);
    }
  }

  // View Attachment
  /**
   * View attachment / location
   *
   * @param {Message} message - target message
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  onMessageClick(message: Message): void {
    if (
      message.type == MessageTypeConstant.TEXT ||
      message.type == MessageTypeConstant.STICKER
    ) {
      return;
    }
    let body;
    let isAttachment: boolean;
    let isImage: boolean = false;
    const isMsgEncrypted = message.parsedBody.is_encrypted === 1;

    if (message.type == MessageTypeConstant.ATTACHMENT) {
      this._loggerService.log('Clicked on message - attachment: ' + message.message_id);
      body = message.attachments;
      isAttachment = true;

      let type = this._fileManagerService.getAttachmentType(message.attachments[0].attachment_id);
      isImage = type == AttachmentTypeConstant.IMAGE;
    } else if (message.type == MessageTypeConstant.LOCATION) {
      this._loggerService.log('Clicked on message - location: ' + message.message_id);
      body = JSON.parse(message.body);
      isAttachment = false;
    }
    if (!isImage) {
      this._attachmentService.openAttachmentModal(
        isAttachment,
        body,
        message.parsedBody.filename,
        true,
        null,
        isMsgEncrypted
      );
    } else {
      // Prepare filename Map
      const prefix = this._translate.instant('GENERAL.TEAMNOTE');
      const filenameMap = {};
      let attachmentIds = [];
      _.forEach(this._chatMessageService.getAllAttachmentUnderChatByType(
        this.chat.chat_id,
        AttachmentTypeConstant.IMAGE,
        false,
        this.isInMultiChatRoomMode ? !_.isEmpty(this.targetMessage) : this.targetMessage !== null// this.targetMessage !== null
      ), (a) => {
        const components = a.attachment_id.split('.');
        const fileName = components[0];
        const extension = components[components.length - 1];
        // @ts-ignore
        const dateStr = new Date(parseFloat(message.submit_time) * 1000).toISOString().replaceAll('.', '').replaceAll(':', '')
          .replaceAll('Z', '');

        filenameMap[a.attachment_id] = `${prefix}_${fileName}.${dateStr}.${extension}`;
        attachmentIds.push(a.attachment_id);
      });

      if (isMsgEncrypted) {
        // if the clicking on message is encrypted, show itself only
        if (message.attachments[0] && message.attachments[0].attachment_id) {
          attachmentIds = [message.attachments[0].attachment_id]
        }
      } 

      this._attachmentService.prepareAttachmentModalContentByFileId(
        attachmentIds,
        message.attachments[0].attachment_id,
        (imageUrl, caption, attachmentId) => {
          this.onImageViewerEditFunction(imageUrl, caption, attachmentId);
        },
        null,
        null,
        isMsgEncrypted,
        true,
        filenameMap,
      );
    }
  }


  // File Inputs
  // File drag & drop
  fileOver(event) {
    if (this.whisperingTarget) {
      return;
    }
    this.isDraggingFileOver = event;
  }

  onFileDrop(event) {
    if (this.whisperingTarget) {
      return;
    }
    this.openUploadFilesModal(event);
  }

  updateIsEnableChatroomAutoScroll(value: any) {
    if (!value) {
      this.isEnableChatroomAutoScroll = null;
    } else {
      // const { isMsgSendAction, isNewMsgAction } = value
      if (_.isEqual(this.isEnableChatroomAutoScroll, value)) {
        return
      }
      
      this.isEnableChatroomAutoScroll = Object.assign({}, value)
    };
  }

  // Normal file input
  handleFileInputChange(e) {
    let files = e.target.files;
    this.validateFiles(files);
  }

  validateFiles(files: File[]) {
    let validFiles = [];
    _.each(files, (f) => {
      let validCode = this._inputValidationService.isFileValidForChatRoom(f);
      switch (validCode) {
        case AttachmentTypeConstant.IS_VALID.VALID:
          validFiles.push(f);
          break;
        case AttachmentTypeConstant.IS_VALID.INVALID_SIZE:
          this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.FILE_SIZE_ERROR');
          break;
        case AttachmentTypeConstant.IS_VALID.INVALID_TYPE:
          this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.FILE_TYPE_ERROR');
          break;
      }
    });
    this.onFinishSelectFile(validFiles);
  }

  /**
   * Open file selector modal
   *
   * @param {File[]} defaultFiles - default selected files
   * @memberof ChatRoomComponent
   */
  openUploadFilesModal(defaultFiles: File[]): void {
    this._loggerService.log('Clicked file selection button');
    this._fileUploaderService.openFileUploaderModal(
      FileUploadTarget.CHATROOM,
      (files) => this.onFinishSelectFile(files),
      defaultFiles
    );
  }

  /**
   * Finished selecting files, upload and sent attachment
   *
   * @param {File[]} files - target files
   * @memberof ChatRoomComponent
   */
  onFinishSelectFile(files: File[]): void {
    this.sendMessageHub(MessageTypeConstant.ATTACHMENT, files);
    this.resetAllInputs();
  }

  // Location Select
  /**
   * Open location selection modal
   *
   * @memberof ChatRoomComponent
   */
  openLocationSelectModal(): void {
    this._loggerService.log('Clicked location selection button');
    this._locationSelectorService.openLocationSelectorModal((location) => this.onFinishSelectLocation(location));
  }

  // Standalone Annotation
  /**
   * Open location selection modal
   *
   * @memberof ChatRoomComponent
   */
  toggleStandaloneAnnotationMode(): void {
    this._loggerService.log('Clicked Standalone Annotation toggle Mode button');
    // this.chatRoomMode = CHAT_ROOM_MODE.ANNOTATE;
    this.annotatingMessage = null;

    if (this.chatRoomMode == CHAT_ROOM_MODE.ANNOTATE) {
      this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
    } else {
      this.resetCurrentChatDraft();
      this.resetAllInputs();
      this.chatRoomMode = CHAT_ROOM_MODE.ANNOTATE
    }
  }

  exitStandaloneAnnotationMode(): void {
    this._loggerService.log('Clicked Exiting Standalone Annotation Mode button');
    this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
  }

  /**
   * Finished selecting location, send location to chat
   *
   * @param {MessageLocationBody} locationBody - location body
   * @memberof ChatRoomComponent
   */
  onFinishSelectLocation(locationBody: MessageLocationBody): void {
    this.sendMessageHub(MessageTypeConstant.LOCATION, locationBody);
    this.resetAllInputs();
  }

  // Chat Group Settings
  /**
   * Open chat group setting modal
   *
   * @memberof ChatRoomComponent
   */
  toggleChatGroupSettings(): void {
    this._loggerService.log('Opening chat group setting dialog...');
    let dialogRef = this._tnDialogService.openTnDialog(
      ChatGroupSettingComponent,
      {
        onMemberAvatarClick: (member) => {
          this.onUserAvatarClick(member);
        },
        targetChat: this.isInMultiChatRoomMode ? this.chat : null,
      }
    );
  }

  // Whisper
  /**
   * Toggle whispering target user
   *
   * @param {UserContact} contact - target user
   * @memberof ChatRoomComponent
   */
  toggleWhisper(contact: UserContact): void {
    this.whisperingTarget = contact;
    this.focusOnInputTextarea();
    this.setInputTextareaPlaceholder();
  }

  // Contact Card
  /**
   * Open user contact card
   *
   * @param {UserContact} contact - target user
   * @memberof ChatRoomComponent
   */
  onUserAvatarClick(contact: UserContact): void {
    this._loggerService.log('Opening contact card dialog...');
    let isAllowWhisper = this.chat.isGroup && this.chat.members.indexOf(contact.user_id) !== -1;
    this._contactCardService.openContactCardModal(
      contact,
      isAllowWhisper,
      (contact) => this.toggleWhisper(contact),
      true
    );
  }

  // Reply
  /**
   * Toggle replying message
   *
   * @param {Message} message - target message
   * @memberof ChatRoomComponent
   */
  toggleReply(event: {message: Message, chatId?: string}): void {
    let message = event?.message || null;
    let chatId = event?.chatId || null;
    this._loggerService.log('Clicked reply to message: ' + (message ? message.message_id : '--cancel reply--'));
    
    if (this.isInMultiChatRoomMode && this.chat.chat_id === chatId) {
      this.showSendBar();
    }

    if (this.annotatingMessage) {
      this.annotatingMessage = null;
    }
    
    this.replyingMessage = message;
    if (message && message.isWhisper) {
      this.toggleWhisper(message.isSentByMe ? message.whisperContact : message.senderContact);
    } else {
      // this.toggleWhisper(null);
    }
    setTimeout(() => {
      this.focusOnInputTextarea();
    }, 0);
  }

  toggleAnnotate(event: {message: Message, chatId?: string}): void {
    let message = event?.message || null;
    let chatId = event?.chatId || null;
    this._loggerService.log('Clicked annotate to message: ' + (message ? message.message_id : '--cancel annotate--'));
    
    if (this.isInMultiChatRoomMode && this.chat.chat_id === chatId) {
      this.showSendBar();
    }

    if (this.replyingMessage) {
      this.clearReplyingMessage();
    }
    
    this.annotatingMessage = message;

    setTimeout(() => {
      this.focusOnInputTextarea();
    }, 0);
  }

  // Chatroom window blur
  /**
   * Handle when chatroom onblur (go to background)
   * 1. try to lock encrypted messages again
   * 2. if chat's newMessageCount is 0, all messages are read, update last message pointer.
   *
   * @param {*} event - blur event
   * @memberof ChatRoomComponent
   */
  @HostListener('window:blur', ['$event']) windowOnBlur(event: any) {
    if (this.isUnlockedEncryptedMessage) {
      this.isUnlockedEncryptedMessage = false;
    }
    // In order to remove "unread messages" bar
    if (this.chat.newMessageCount == 0) {
      this.updateLastMessagePointer();
    }
  }

  // Encrypted Message
  /**
   * Toggle sending encrypted message or not
   *
   * @memberof ChatRoomComponent
   */
  toggleEncryptedMessage(): void {
    this._loggerService.log('Clicked encrypted message toggle lock');
    this.isToggledEncryptedMessage = !this.isToggledEncryptedMessage;
  }

  /**
   * Clicked on encrypted message to unlock, show re-login page
   *
   * @param {boolean} isUnlock - is message unlocked
   * @memberof ChatRoomComponent
   */
  onEncryptedMsgUnlock(message: Message): void {
    if (this.idleTimeout) {
      // clear the idle timeout after clicking on encrypted message
      clearTimeout(this.idleTimeout);
    }

    this._loggerService.log('Clicked encrypted message to unlock messages');
    this.reloginAction = this.RELOGIN_ACTIONS.ENCRYPTED;
    this.reloginOverlayTitle = 'WEBCLIENT.CHATROOM.ENCRYPTED_MESSAGE.AUTH_TITLE';
    this.requestedUnlockMessage = message;
  }

  // Classification
  /**
   * ##for CONFIDENTIAL chat
   *
   * Try to clear idleTimout's timeout
   *
   * @param {boolean} isSetIdleTimeout - is idle timeout set or not
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  resetIdleTimeout(isSetIdleTimeout: boolean): void {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }
    if (!isSetIdleTimeout) {
      return;
    }
    if (this.chat.security_level == ChatConstant.SECURITY_LEVEL.RESTRICTED) {
      this._loggerService.debug('setTimeout for leaving confidential chat');
      this.idleTimeout = setTimeout(
        () => {
          this.removeConfidentialChatMessages();

          if (this.targetMessage) {
            // reset the active chatroom by clearing the target message 
            this.exitSearchedMessageDisplayMode();
          } else {
            this.setUpChatRoom();
          }
        },
        this.targetIdleSecond * 1000
      );
    }
  }

  /**
   * Lock confidential chat, go to relogin page
   *
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  lockConfidentialChat(): void {
    if (this.chat.security_level == ChatConstant.SECURITY_LEVEL.RESTRICTED) {
      this._loggerService.debug('Locking confidential chat');

      // TODO: handle opened dialogs

      // this._tnDialogService.closeAllDialogs();
      // this.reEnterChatroom.emit(this.chat);
      // this.chatRoomBack();
      this.reloginOverlayTitle = 'WEBCLIENT.CHATROOM.CLASSIFICATION_LEVEL.AUTH_TITLE';
      this.reloginAction = this.RELOGIN_ACTIONS.CONFIDENTIAL;
    }
  }

  removeConfidentialChatMessages(): void {
    if (this.chat.security_level == ChatConstant.SECURITY_LEVEL.RESTRICTED) {
      // remove chat messages locally
      this._chatRoomService.removeLocalChatHistoryByChatId(this.chat.chat_id);
    }
  }

  /**
   * Draw security overlay on chat room
   *
   * @memberof ChatRoomComponent
   */
  drawSecurityOverlay() {

    if (this._watermarkService.checkIfEnabledFullScreenMode()) {
      return;
    }

    let canvas = this.securityOverlayCanvas.nativeElement;
    let ctx = canvas.getContext('2d');
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    if (this.chat.security_level) {
      this._watermarkService.drawWatermark(ctx, canvas.width, canvas.height);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  // Relogin
  /**
   * Re-login success handler
   *
   * @param {boolean} isSuccess - if login is success or not
   * @memberof ChatRoomComponent
   */
  onReloginSuccess(isSuccess: boolean) {
    switch (this.reloginAction) {
      case this.RELOGIN_ACTIONS.ENCRYPTED:
        if (isSuccess) {
          this.isUnlockedEncryptedMessage = true;
        } else {
          this.reloginAction = this.RELOGIN_ACTIONS.NONE;
        }
        // Scroll to original message
        setTimeout(() => {
          this.scrollToTargetMessage(this.requestedUnlockMessage);
          this.requestedUnlockMessage = null;
        }, 500);
        break;
      case this.RELOGIN_ACTIONS.CONFIDENTIAL:
        if (isSuccess) {
          if (this.isSentInitialRead) {
            this.reloginAction = this.RELOGIN_ACTIONS.NONE;
          } else {
            this.initializeChatRoom();
          }
        } else {
          this.chatRoomBack();
        }
        break;
    }
  }

  // Selection
  /**
   * Update chat room mode to target mode
   *
   * @param {number} mode - target mode
   * @memberof ChatRoomComponent
   */
  updateChatRoomMode(mode: number) {
    this.chatRoomMode = mode;
  }

  /**
   * Update selected messages
   *
   * If no mode is provided, keep current mode
   *
   * @param {string[]} messageIds - target selected message ids
   * @param {number} [mode] - target chatroom mode
   * @memberof ChatRoomComponent
   */
  updateSelectedMessages(messageIds: string[], mode?: number): void {
    this.chatRoomMode = mode ? mode : this.chatRoomMode;
    this.selectedMessageIds = messageIds;
  }

  /**
   * Cancel message selection
   *
   * @memberof ChatRoomComponent
   */
  cancelSelection(): void {
    this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
    this.selectedMessageIds = [];
  }

  get downloadableSelections() {
    let downloadable = [];

    for (let i = 0; i < this.selectedMessageIds.length; i++) {
      const messageId = this.selectedMessageIds[i];
      const msg = this._chatMessageService.getChatMessageByMessageId(messageId);
      if (msg && msg.attachments.length > 0) {
        downloadable.push(msg);
      }
    }

    return downloadable;
  }

  downloadSelection(): void {
    if (this.downloadableSelections.length === 0) {
      return;
    }

    const attachmentIds = [];
    const filenameMap = {};
    const prefix = this._translate.instant('GENERAL.TEAMNOTE');

    for (let i = 0; i < this.downloadableSelections.length; i++) {
      const message = this.downloadableSelections[i];
      _.forEach(message.attachments, (a) => {
        attachmentIds.push(a.attachment_id);

        const components = a.attachment_id.split('.');
        const fileName = components[0];
        const extension = components[components.length - 1];

        // @ts-ignore
        const dateStr = new Date(parseFloat(message.submit_time) * 1000).toISOString().replaceAll('.', '').replaceAll(':', '')
          .replaceAll('Z', '');

        filenameMap[a.attachment_id] = `${prefix}_${fileName}.${dateStr}.${extension}`;
      });
    }

    // @ts-ignore
    const dateStr = new Date().toISOString().replaceAll('.', '').replaceAll(':', '').replaceAll('Z', '');
    this._fileManagerService.downloadFilesByAttachmentIds(attachmentIds, `${prefix}_${dateStr}.zip`, (a) => {
      return filenameMap[a] || a;
    });
    this.chatRoomMode = CHAT_ROOM_MODE.NORMAL;
    this.selectedMessageIds = [];
  }

  /**
   * Confirm selected messages
   * 1. Route according to chatRoomMode
   *
   * @returns {void}
   * @memberof ChatRoomComponent
   */
  confirmSelection(): void {
    if (this.selectedMessageIds.length == 0) {
      return;
    }
    switch (this.chatRoomMode) {
      case CHAT_ROOM_MODE.FORWARD:
        this._contactPickerService.openContactPicker('WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.FORWARD', true, true, true, (targets) => this.forwardMessage(targets, this.selectedMessageIds), true, CONTACT_PICKER_ACTION.FORWARD);
        break;
    }
  }

  // Forward
  /**
   * Forward messages to targets array
   *
   * Try to send in sequence every 250ms (//TODO: implement real message queue)
   *
   * @param {any[]} targets - array of target user / target chat
   * @param {string[]} messageIds - array of selected message ids
   * @memberof ChatRoomComponent
   */
  forwardMessage(targets: any[], messageIds: string[]): void {
    let allMsgs = []
    /* forward under searching mode */
    if (this.targetMessage) {
      allMsgs = _.map(messageIds, (mId) => {
        return _.find(this.messages, ['message_id', mId])
      });
    } else {
      allMsgs = _.map(messageIds, (mId) => {
        return this._chatMessageService.getChatMessageByMessageId(mId);
      });
    }
   
    allMsgs = _.sortBy(allMsgs, 'timestamp');

    let index = 0;
    let interval = setInterval(() => {
      if (index >= allMsgs.length) {
        clearInterval(interval);
        this.cancelSelection();
      } else {
        let m = allMsgs[index++];
        this._chatRoomService.forwardMessage(targets, m);
      }
    }, 250);
  }

  // Out of office
  /**
   * Start tracking chat room's user's state
   *
   * Add chat.memebrs to tracking user contact ids
   *
   * @memberof ChatRoomComponent
   */
  trackChatRoomUserState(): void {
    // Reset first
    this.numOfOutOfOfficeMember = 0;
    this.outOfOfficeExpiredTimestamp = 0;

    let trackingMembers = this.chat.members;
    if (!this.isGroupChat) {
      trackingMembers = _.without(trackingMembers, this._accountManagerService.userId);
    }
    this._userContactService.setTrackingUserContactIds(trackingMembers);
    this._userContactService.trackingUserContacts$.subscribe(users => {
      this.checkUserOutOfOfficeState(users);
    });
  }

  /**
   * Check how many users are out of office
   *
   * @param {UserContact[]} users - chat member's user contact object array
   * @memberof ChatRoomComponent
   */
  checkUserOutOfOfficeState(users: UserContact[]): void {
    let num = 0;
    let time = 0;
    _.each(users, (u) => {
      if (this._userContactService.checkIfUserIsOutOfOffice(u)) {
        num++;
        time = u.user_state.expired_at;
      }
    });
    this.numOfOutOfOfficeMember = num;
    this.outOfOfficeExpiredTimestamp = time;
  }

  // Export Chat Messages
  toggleExportChatMessageOption(): void {
    this._tnDialogService.openTnDialog(ExportMessageComponent, {
      chat: this.chat
    });
  }

  // Attachment views
  openChatPhoto(): void {
    this._tnDialogService.openTnDialog(
      AttachmentImageGridComponent,
      {
        attachments: this._chatMessageService.getAllAttachmentUnderChatByType(
          this.chat.chat_id,
          AttachmentTypeConstant.IMAGE,
          false,
          this.isInMultiChatRoomMode ? !_.isEmpty(this.targetMessage) : this.targetMessage !== null
        ),
        editImageCallbackWithImageUrlAndCaption: (imageUrl, caption, attachmentId) => {
          this.onImageViewerEditFunction(imageUrl, caption, attachmentId);
        }
      }
    );
  }

  openChatVideo(): void {
    this._tnDialogService.openTnDialog(
      AttachmentVideoGridComponent,
      {
        attachments: this._chatMessageService.getAllAttachmentUnderChatByType(
          this.chat.chat_id,
          AttachmentTypeConstant.VIDEO,
          false,
          this.isInMultiChatRoomMode ? !_.isEmpty(this.targetMessage) : this.targetMessage !== null
        )
      }
    );
  }

  openChatDocument(): void {
    this._tnDialogService.openTnDialog(
      AttachmentPdfListComponent,
      {
        attachments: this._chatMessageService.getAllAttachmentUnderChatByType(
          this.chat.chat_id,
          AttachmentTypeConstant.PDF,
          false,
          this.isInMultiChatRoomMode ? !_.isEmpty(this.targetMessage) : this.targetMessage !== null
        )
      }
    );
  }

  openStarredMessageMenu(): void {
    this._tnDialogService.openTnDialog(
      StarredMessagesComponent,
      {
        isInChat: true,
        chat: this.chat
      },
      {
        width: '50vw',
        minWidth: '550px',
        height: '80vh'
      }
    );
  }
  openChatroomList(): void {
    // this.selectChatRoom.emit(this.chat);
  }

  subscribeScrollTriggerSubject(): void {
    this._chatRoomService.initTriggerScrollSubject(this.chat.chat_id);
      
    this._chatRoomService.getTriggerScrollSubjectByChatId(this.chat.chat_id).subscribe((updated) => {
      // this.onQuickTravelClick();
      if (updated) {
        setTimeout(() => {
          if (this.messagesElement) {
            let element = this.messagesElement.nativeElement;
            element.scrollTop = this.chat.scrollTop || 0
      
            // console.log('<<< subscribeScrollTriggerSubject >>>', element.scrollTop);
          }
        }, 10);
      }
    });
  }

  // Paste Image Data
  onInputAreaPasteEvent(event: ClipboardEvent): void {
    if (this._teamnoteConfigService.isBrowserIE) {
      return;
    }
    if (this.whisperingTarget) {
      return;
    }
    this._pasteImageHelperService.getImageFromClipboardAsDataUrl(
      event,
      (dataUrl) => {
        this._imageEditorService.openImageEditorModal(
          dataUrl,
          (dataUrl, imageCaption) => {
            let newImageFile = this._fileManagerService.dataUrlToFile(dataUrl, 'paste-image.jpg');
            newImageFile.caption = imageCaption;
            this.onFinishSelectFile([newImageFile]);
          },
          true,
          FileUploadTarget.CHATROOM
        );
      }
    );
  }

  // Image Viewer Edit Function
  onImageViewerEditFunction(imageDataUrl: string, caption: string, attachmentId: string): void {
    this._imageEditorService.openImageEditorModal(
      imageDataUrl,
      (dataUrl, newImageCaption) => {
        this._contactPickerService.openContactPicker(
          'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.FORWARD',
          true,
          true,
          true,
          (targets) => {
            let newImageFile = this._fileManagerService.dataUrlToFile(dataUrl, attachmentId.replace('.jpg', '_edit.jpg').replace('.png', '_edit.jpg'));
            newImageFile.caption = newImageCaption;
            this._fileManagerService.apiUploadFile(
              newImageFile,
              (fileId, imageCaption) => {
                let messageBody = this._chatRoomService.getAttachmentMessageBody(
                  fileId,
                  newImageFile,
                  imageCaption,
                  null,
                  null
                );
                this._chatRoomService.forwardMessage(
                  targets,
                  null,
                  messageBody,
                  MessageTypeConstant.ATTACHMENT
                );
                this._tnDialogService.closeAllDialogs();
              },
              false
            );
          },
          true,
          CONTACT_PICKER_ACTION.FORWARD
        );
      },
      false,
      FileUploadTarget.CHATROOM,
      null,
      caption
    );
  }

  // parent message click
  onMessageParentClick(event: { msg: Message, prevMsg: Message }): void {
    if (this.idleTimeout) {
      // clear the idle timeout after clicking on message parent 
      clearTimeout(this.idleTimeout);
    }

    this.targetMessageWasFlashed = false;

    if (!this.isInMultiChatRoomMode) {
      this.enterSearchModeAndGoToChat.emit({msg: event.msg, prevMsg: event.prevMsg});
    } else {
      this.enterSearchModeWithChatIdAndGoToChat.emit({msg: event.msg, prevMsg: event.prevMsg, chatId: this.chat.chat_id});
    }
  }

  // Chat search
  searchInChat(): void {
    this.enterChatSearchMode.emit({chat: this.chat, keyword: ''});
  }

  onChatSearchByKeyword(keyword: string): void {
    this.enterChatSearchMode.emit({chat: this.chat, keyword: keyword});
  }

  // @ #
  tryToCancelMentionHashtag(): void {
    if (this.chatRoomMode == CHAT_ROOM_MODE.ANNOTATE || this.annotatingMessage) {
      return;
    }
    
    if (this.checkIfMentionShouldCancel()) {
      this.cancelMentionMode();
    }
    if (this.checkIfHashtagShouldCancel()) {
      this.cancelHashtagMode();
    }
  }

  // Check @ mention
  checkMentionAndHashtagShouldInitiate(char: string): boolean {
    if (this.inputMessage.length == 1 && this.inputMessage[0] == char) {
      return true;
    }
    let lastChars = this.inputMessage.substr(this.inputMessage.length - 2);
    if (lastChars == ` ${char}` || lastChars == `\n${char}`) {
      return true;
    }

    // check empty string before '@' & any char after '@'
    let regex = /s*@(\S+)/ 
    if (regex.exec(this.inputMessage)) {
      return true;
    }

    return false;
  }

  checkIfMentionShouldInitiate(): boolean {
    if (!this.isEnableMention) {
      return false;
    }
    // only show in group chat
    if (!this.isGroupChat) {
      return false;
    }

    if (this.isEnableMarkdownMessageInput) {
      return this.checkMentionAndHashtagShouldInitiateForEditableDiv('@');
    }

    return this.checkMentionAndHashtagShouldInitiate('@');
  }

  checkIfMentionShouldCancel(): boolean {
    // moved input cursor
    // let textarea: HTMLTextAreaElement = this.messageInputTextarea.nativeElement;
    let textarea: HTMLTextAreaElement = null;

    if (!this.isEnableMarkdownMessageInput) {
      textarea = this.messageInputTextarea.nativeElement
    } else {
      textarea = this.editableDiv.nativeElement
    }

    if (this.isInMentionMode) {
      if (textarea.selectionStart < this.inputMessage.length) {
        return true;
      }
    }
    return false;
  }

  onMentionInitiate(): void {
    this.isInMentionMode = true;
  }

  cancelMentionMode(): void {
    this.isInMentionMode = false;
  }

  getCharAfterMentionChar(): string {
    if (this.isEnableMarkdownMessageInput) {
      const editor = this.editableDiv.nativeElement;
      let lastIndex = editor.innerText.lastIndexOf('@');
      return editor.innerText.substr(lastIndex + 1).toLowerCase();
    }

    let lastIndex = this.inputMessage.lastIndexOf('@');
    return this.inputMessage.substr(lastIndex + 1).toLowerCase();
  }

  updateMentionSelectionList(): void {
    if (!this.isEnableMarkdownMessageInput) {
      if (this.inputMessage.lastIndexOf('@') == -1) {
        this.cancelMentionMode();
        return;
      }
    } else {
      const editor = this.editableDiv.nativeElement;
      if (editor.innerText.lastIndexOf('@') == -1) {
        this.cancelMentionMode();
        return;
      }
    }
    
    let latestChar = this.getCharAfterMentionChar();
    let selections = this.chat.members.map((m) => {
      return this._userContactService.getUserContactByUserId(m);
    });
    // filter members
    selections = selections.filter((member) => {
      if (member.user_id == this._accountManagerService.userId) {
        return false;
      }
      return latestChar.length > 0 ? member.name.toLowerCase().indexOf(latestChar) !== -1 : true;
    });

    selections.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    this.mentionSelectionList = selections;
  }

  selectMentionUser(user: UserContact): void {
    if (!this.isEnableMarkdownMessageInput) {
      this.updateMessageWithSelectedUser(user);
    } else {
      this.updateMarkdownMessageWithSelectedUser(user);
    }
    this.cancelMentionMode();
  }

  updateMessageWithSelectedUser(user: UserContact): void {
    let lastIndex = this.inputMessage.lastIndexOf('@');
    let original = this.inputMessage.substr(0, lastIndex + 1);
    this.inputMessage = original + user.name + ' ';
    this.focusOnInputTextarea();

    this.mentionedMembers.push({
      length: user.name.length,
      location: lastIndex + 1,
      user_id: user.user_id,
      name: user.name
    });
  }


  // Check # hashtag
  checkIfHashtagShouldInitiate(): boolean {
    if (!this.isEnableHashtag) {
      return false;
    }
    return this.checkMentionAndHashtagShouldInitiate('#');
  }

  checkIfHashtagShouldCancel(): boolean {
    // moved input cursor
    // let textarea: HTMLTextAreaElement = this.messageInputTextarea.nativeElement;
    let textarea: HTMLTextAreaElement = null;

    if (!this.isEnableMarkdownMessageInput) {
      textarea = this.messageInputTextarea.nativeElement
    } else {
      textarea = this.editableDiv.nativeElement
    }

    if (this.isInHashtagMode) {
      if (textarea.selectionStart < this.inputMessage.length) {
        return true;
      }
    }
    return false;
  }

  onHashtagInitiate(): void {
    this.isInHashtagMode = true;
    this.getChatHashtagList();
  }

  cancelHashtagMode(): void {
    this.isInHashtagMode = false;
  }

  getChatHashtagList(): void {
    this._chatRoomService.getChatTags(
      this.chat.chat_id,
      resp => {
        this.chatTags = resp.tags;
        this.updateHashtagSelectionList();
      },
      err => {

      }
    );
  }

  getCharAfterHashtagChar(): string {
    let lastIndex = this.inputMessage.lastIndexOf('#');
    return this.inputMessage.substr(lastIndex + 1).toLowerCase();
  }

  updateHashtagSelectionList(): void {
    if (this.inputMessage.lastIndexOf('#') == -1) {
      this.cancelHashtagMode();
      return;
    }
    let latestChar = this.getCharAfterHashtagChar();
    let selections = this.chatTags;
    // filter members
    selections = selections.filter((tag) => {
      return latestChar.length > 0 ? tag.toLowerCase().indexOf(latestChar) !== -1 : true;
    });

    selections.sort((a, b) => {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    this.hashtagSelectionList = selections;
  }

  selectHashtag(tag: string): void {
    this.updateMessageWithHashtag(tag);
    this.cancelHashtagMode();
  }

  updateMessageWithHashtag(tag: string): void {
    let lastIndex = this.inputMessage.lastIndexOf('#');
    let original = this.inputMessage.substr(0, lastIndex + 1);
    this.inputMessage = original + tag + ' ';
    this.focusOnInputTextarea();
  }

  // Attach
  openAttachModal(): void {
    let fileAllowTypes = [
      TeamNoteCorporateMaterialConstant.TYPE.TXT
    ];

    this._corporateMaterialPickerService.openCorporateMaterialPicker(
      'Attach',
      true,
      false,
      false,
      (file: CorporateMaterialFile) => {
        this._fileManagerService.getHTMLContentByAttachmentId(
          file.attachment_id,
          (content) => {
            this.inputMessage += content;
          }
        );
      },
      fileAllowTypes
    );
  }

  // OIDS SMS Limit
  getLengthLimitTip(): number {
    if (this._inputValidationService.checkIfTextHasChineseChar(this.inputMessage)) {
      return 330;
    }

    return 750;
  }

  // Select from document sharing
  openSelectFromDoc(): void {
    this._corporateMaterialPickerService.openCorporateMaterialPicker(
      'Document Sharing',
      true,
      false,
      false,
      (file: CorporateMaterialFile) => {
        let f = {
          name: file.name,
          size: file.attachment.size,
          type: file.attachment.content_type
        };
        this._chatRoomService.sendAttachmentMessage(
          this.chat.chat_id,
          file.attachment_id,
          f,
          null,
          this.whisperingTarget,
          this.replyingMessage,
          this.isToggledEncryptedMessage,
          () => {
            this.updateLastMessagePointer();
          },
          file.name
        );
      }
    );
  }

  // Sticker
  toggleStickerSelection(): void {
    this.isOpenStickerSelection = !this.isOpenStickerSelection;
  }

  onStickerClick(sticker: Sticker): void {
    this.sendMessageHub(
      MessageTypeConstant.STICKER,
      {attachment_id: sticker.attachment_id}
    );
    this.scrollToBottom = true;
    this.resetAllInputs();
  }

  getUserField(): void {
    this.showUserField = false;
    if (this._webclientService.display_user_fields_in_chat && Object.keys(this._webclientService.display_user_fields_in_chat).length > 0) {
      this.showUserField = true;
      this.userField = this._webclientService.display_user_fields_in_chat;
    }
  }

  get isShowSendBarInMultiChatroomMode() {
    return this.isInMultiChatRoomMode && this.isShowSendBar;
  }

  showSendBar(): void {
    if (!this.isShowSendBar) {
      // show send bar for target chat room in multi chat room panel
      this.isShowSendBar = true;

      setTimeout(() => {
        this.focusOnInputTextarea();
      }, 0);
    }
  }

  toggleSendBar() {
    if (this.isMemberFiltering || this.isDateTimeFiltering) {
      return
    }

    if (!this.isShowSendBar) {
      this.showSendBar()
    } else {
      this.isShowSendBar = false;
    }
  }

  disableSendBar(event?: MouseEvent): void {
    event.stopPropagation();
    this.closeSendBarAndReplyBar();
  }

  closeSendBarAndReplyBar(): void {
    this.isShowSendBar = false;
    if (this.replyingMessage) {
      this.clearReplyingMessage()
    }

    if (this.annotatingMessage) {
      this.annotatingMessage = null;
    }
  }

  clearReplyingMessage(): void {
    this.replyingMessage = null;
    this._chatRoomService.updateReplyingMessage(null) // reset replying messaged in chat room service
  }

  updateScrollBarPosToBottom(): void {
    this.storePrevScrollPos();
  }

  get isShowNoMessageOverlay() {
    if (!this.isInMultiChatRoomMode) {
      return false;
    }

    if (this.isMemberFiltering || this.isDateTimeFiltering) {
      let filteredMessages = _.cloneDeep(this.messages);

      const membersIds = _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id') || [];
      if (membersIds.length) {
        if (!_.intersection(membersIds, this.chat.members).length) {
          return true;
        }
        
        filteredMessages = this._chatMessageService.filterMessageBySelectedUser(filteredMessages, membersIds, this.chat);
        // if (this.isAtChatEarliest && filteredMemberMessages.length === 0) {
        //   return true;
        // }
      }

      const dateTimeRange = this.multiChatFilterOptions.selectedDateRange;
      if (!_.isEmpty(dateTimeRange)) {
        filteredMessages = this._chatMessageService.filterMessageByDateTimeRange(filteredMessages, dateTimeRange.from);
      }

      if (this.isAtChatEarliest && filteredMessages.length === 0) {
        return true;
      }
    }

    return false
  }

  get isMemberFiltering() {
    if (this.multiChatFilterOptions) {
      return this.multiChatFilterOptions.selectedChatMembers.length
    }

    return false
  }

  get isDateTimeFiltering() {
    // if (this.targetMessage) {
    //   return !_.isEmpty(this.targetMessage.timestamp_from);
    // }

    if (this.multiChatFilterOptions) {
      return !_.isEmpty(this.multiChatFilterOptions.selectedDateRange)
    }

    return false
  }



  actionsAfterResetFilter(): void {

  }

  checkFilterIfNeedReset(): void {
    if (!this.multiChatFilterOptions) {
      return;
    }

    const membersFilterChanged = !_.isEqual(this.prevMembersFilterSettings, this.currentMembersFilterSettings);
    const datetimeFilterChanged = !_.isEqual(this.prevDateTimeFilterSettings, this.currentDateTimeFilterSettings);

    if(membersFilterChanged || datetimeFilterChanged) {
      // if (!_.isEmpty(this.prevMembersFilterSettings)) {
        // reseted the members filter
        console.log('need reset load size');
        this.updateCurrentMessages([]);
      // }
    } else {
      // TODO ....(e.g prevent filter again?)
    }
  }

  updateFirstFilteredMessagePointer(): void {
    // if (!this.isMemberFiltering) {
    //   console.log('!this.isMemberFiltering but updateFirstMessagePointer');
    //   this.updateFirstMessagePointer();
    //   return;
    // }
    if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
      this.updateFirstMessagePointer();
      return;
    }

    let filteredMessages = _.cloneDeep(this.messages);

    if (this.isDateTimeFiltering) {
      if (this.targetMessage?.timestamp_from) {
        filteredMessages = _.filter(filteredMessages, (m) => m.timestamp >= this.targetMessage.timestamp_from)
      }
    }
    
    const membersIds = _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id');
    if (membersIds.length) {
      filteredMessages = this._chatMessageService.filterMessageBySelectedUser(filteredMessages, membersIds, this.chat);
    }

    this.updateFirstMessagePointer(filteredMessages);
  }

  tryToUnsubscribePrevMessageSubject(): void {
    this.unsubscribeMultiChatRoomMessageSubject();
    this.unsubscribeSearchingModeBehaviorSubject();
  }

  clearAutoMsgHistoryApiLoaderStuff(): void {
    console.log('clearAutoMsgHistoryApiLoaderStuff');
    // this.isNeedStopAutoLoading = true;

    clearTimeout(this.autoLoadMessageTimer);
    this.autoLoadMessageTimer = null

    this.isLoadingHistory = false;
    // this.updateChatLoadingHistoryState.emit({chatId: this.chat.chat_id, loadingState: this.isLoadingHistory})
  }

  checkIfNeedSetUpChatRoomInFilteringMode(): boolean {
    if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
      return false;
    }

    if(_.isEqual(this.prevDateTimeFilterSettings, this.currentDateTimeFilterSettings)) {
      // checking when the previous date-time filter is no changes
      
      if(_.isEqual(this.prevMembersFilterSettings, this.currentMembersFilterSettings)) {
        // if (!_.isEmpty(this.prevMembersFilterSettings)) {
          // console.log(this.currentMembersFilterSettings, this.chat.members);
          // if (!_.intersection(this.currentMembersFilterSettings, this.chat.members).length) {}
          // console.log('this chat room is not affected by the filter, no need to setUpChatRoom again', this.chat.isGroup ? this.chat.name : this.chat.chatTarget.name);
          return true;
        // }
      }
    }

    return false;
  }

  get prevMembersFilterSettings() {
    return this.prevFilterSettings ? _.map(this.prevFilterSettings.selectedChatMembers, 'user_id') : null;
  }

  get currentMembersFilterSettings() {
    return this.currFilterSettings ? _.map(this.currFilterSettings.selectedChatMembers, 'user_id') : null;
  }

  get prevDateTimeFilterSettings() {
    return this.prevFilterSettings ? this.prevFilterSettings.selectedDateRange : null;
  }

  get currentDateTimeFilterSettings() {
    return this.currFilterSettings ? this.currFilterSettings.selectedDateRange : null;
  }


  get isLoadingMessageUnderFilteringMode () {
    if (!this.isInMultiChatRoomMode) {
      return false;
    }
    
    return this.isLoadingHistory;
  }

  stopLoadingHistoryLoop(): void {
    this.isNeedStopAutoLoading = true
  }

}
